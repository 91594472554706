<template>
  <div
    class="locations-list-mobile"
    :class="{
      'locations-list-mobile--open': isOpenLocationDropdown,
    }"
  >
    <button
      @click="toggleLocationMenu"
    >
      {{ locale === "en" ? currentLocation.nameEn : currentLocation.name }}
      <svg width="7" height="5" viewBox="0 0 11 6" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.49998 6C5.30284 6 5.10572 5.92797 4.95542 5.78422L0.225656
          1.25832C-0.0752187 0.970413 -0.0752187 0.503627 0.225656 0.21584C0.526409
          -0.0719468 1.01413 -0.0719468 1.31503 0.21584L5.49998 4.22061L9.68496
          0.21598C9.98584 -0.0718069 10.4735 -0.0718069 10.7742 0.21598C11.0753
          0.503767 11.0753 0.970553 10.7742 1.25846L6.04455 5.78436C5.89417 5.92814
          5.69705 6 5.49998 6Z"/>
      </svg>
    </button>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'LocationsListMobile',
  props: {
    currentLocation: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['toggleLocationMenu'],
  setup(props, { emit }) {
    const isOpenLocationDropdown = ref(false);
    const { locale } = useI18n();

    const toggleLocationMenu = () => {
      emit('toggleLocationMenu');
      isOpenLocationDropdown.value = !isOpenLocationDropdown.value;
    };

    return {
      locale,
      isOpenLocationDropdown,
      toggleLocationMenu,
    };
  },
};
</script>

<style lang="scss">
.locations-list-mobile {
  display: none;
  button {
    display: flex;
    align-items: center;
    padding: 8px;
    color: $blue500;
    text-decoration: none;
    border: none;
    border-radius: 3px;
    background-color: $blue100;

    svg {
      margin-left: 4px;
      fill: $blue500;
    }
  }

  &--open {
    button {
      background-color: $blue500;
      color: #fff;

      svg {
        fill: #fff;
        transform: rotate(180deg);
      }
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .locations-list-mobile {
    display: block;
  }
}
</style>
