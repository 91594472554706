export const INSPECTIONS_TABS_LABELS = [
  {
    id: 'passed',
    label: 'past',
  },
  {
    id: 'present',
    label: 'upcoming',
  },
];

export const INSPECTIONS_TYPES = {
  '2196f3': {
    label: 'materialBase',
    color: 'rgba(232,189,101, .2)',
    barColor: '#E8BD65',
  },
  '32A429': {
    label: 'serviceControl',
    color: 'rgba(0,173,236, .2)',
    barColor: '#00ADEC',
  },
  other: {
    label: 'otherChecks',
    color: '#C1C5CA',
    barColor: '#7D8289',
  },
};
