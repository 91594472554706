import timelineModel from '@/models/timeline';
import { prepareShortMonthDate } from '@/helpers/datesAndTime';
import { TIMELINE_CURRENT_TAB_ID } from '@/constants/timeline';

const LENDERS_TIMELINE_TABS = [
  {
    id: 1,
    label: 'Волочаевка',
  },
  {
    id: 2,
    label: 'Симагино',
  },
  {
    id: 3,
    label: 'Лесколово',
  },
];

export default {
  namespaced: true,
  state: () => ({
    currentTimeline: [],
    timelineAllLocation: {},
    mediaReports: [],
    timelineTabs: [],
    currentTab: {},
  }),
  mutations: {
    'set:current:timeline': function (state, capitalizationStages = []) {
      const groupedConstructionSteps = capitalizationStages.reduce((acc, curr) => {
        const updatedAcc = acc;
        if (curr.constructionList && curr.constructionList.length) {
          curr.constructionList.forEach(({ groupId }, indexStep) => {
            updatedAcc[groupId] = updatedAcc[groupId] && updatedAcc[groupId].length
              ? [...updatedAcc[groupId], curr.constructionList[indexStep]]
              : [curr.constructionList[indexStep]];
          });
        }

        return updatedAcc;
      }, {});

      state.currentTimeline = capitalizationStages.reduce((acc, capitalizationStage) => {
        const preparedStages = [{
          capitalizationStageName: capitalizationStage.capitalizationStageName,
          capitalizationStageNameEn: capitalizationStage.capitalizationStageNameEn,
          capitalizationPrice: capitalizationStage.capitalizationPrice,
          marketPrice: capitalizationStage.marketPrice,
          capitalizationStageDescription: capitalizationStage.capitalizationStageDescription,
          capitalizationStageDescriptionEn: capitalizationStage.capitalizationStageDescriptionEn
            || null,
        }];

        if (capitalizationStage.constructionList && capitalizationStage.constructionList.length) {
          capitalizationStage.constructionList.forEach(({
            constructionStageId,
            constructionStageName,
            constructionStageNameEn,
            constructionStatusName,
            constructionStatusId,
            constructionStageDescription,
            constructionStageDescriptionEn,
            finishDate,
            isParent,
            groupId,
          }, constructionIndex) => {
            preparedStages[constructionIndex] = {
              ...preparedStages[constructionIndex],
              constructionStageName,
              constructionStageNameEn,
              status: constructionStatusName === 'Покупка' ? 4 : constructionStatusId,
              date: finishDate ? prepareShortMonthDate(finishDate, { day: true }) : '',
              isHeader: isParent || constructionStatusName === 'Покупка',
              isLast: groupedConstructionSteps[groupId]
                .findIndex((step) => step.constructionStageId === constructionStageId)
                === groupedConstructionSteps[groupId].length - 1,
              isStar: constructionStatusName === 'Покупка',
              constructionStageDescription: constructionStageDescription || null,
              constructionStageDescriptionEn: constructionStageDescriptionEn || null,
            };
          });
        }

        return [...acc, ...preparedStages];
      }, []);
    },
    'set:current:timeline:by:location': function (state, constructionStages = []) {
      state.currentTimeline = constructionStages
        .sort((a, b) => a.seq - b.seq).reduce((acc, curr) => {
          const preparedStages = [...acc];
          const currPreparedItem = {
            title: curr.constructionStageName,
            titleEn: curr.constructionStageNameEn,
            date: curr.finishDate ? prepareShortMonthDate(curr.finishDate, { day: true }) : null,
            constructionStageDescription: curr.constructionStageDescription || null,
            constructionStageDescriptionEn: curr.constructionStageDescriptionEn || null,
            status: curr.constructionStatusId,
          };

          if (!preparedStages.length) {
            return [currPreparedItem];
          }

          const lastElement = preparedStages[preparedStages.length - 1];

          if (curr.level === 1) {
            preparedStages.push(currPreparedItem);
          } else if (curr.level === 2) {
            lastElement.list = lastElement.list
              ? [...lastElement.list, currPreparedItem]
              : [currPreparedItem];

            preparedStages[preparedStages.length - 1] = lastElement;
          } else {
            const lastChild = lastElement.list[lastElement.list.length - 1];
            lastElement.list[lastElement.list.length - 1].innerList = lastChild.innerList
              ? [...lastChild.innerList, currPreparedItem]
              : [currPreparedItem];

            preparedStages[preparedStages.length - 1] = lastElement;
          }

          return preparedStages;
        }, []);
    },
    'set:media:reports:unit': function (state, reports = []) {
      if (!reports || !reports.length) {
        state.mediaReports = [];
        return;
      }

      const preparedMediaReports = [];
      reports.forEach((report) => {
        if (Object.keys(report).length === 0 && report.constructor === Object) {
          return;
        }

        preparedMediaReports.push({
          date: prepareShortMonthDate(report.reportDate),
          filterDate: new Date(report.reportDate).getTime(),
          title: report.constructionStageName || report.title,
          titleEn: report.constructionStageNameEn || report.titleEn,
          subtitle: report.subtitle || '',
          subtitleEn: report.subtitleEn || '',
          cover: report.uploads.length ? report.uploads[0].publicUrl : undefined,
          slides: report.uploads.length ? report.uploads.map((media) => ({
            url: media.publicUrl,
            isVideo: media.mimeType === 'VIDEO',
          })) : [],
        });
      });

      if (state.currentTab.id === 1488) {
        state.mediaReports = preparedMediaReports.sort((a, b) => b.filterDate - a.filterDate);
      } else {
        state.mediaReports = preparedMediaReports;
      }
    },
    'set:media:reports:location': function (state, reports = []) {
      if (!reports || !reports.length) {
        return;
      }

      const preparedMediaReports = [];
      reports.forEach((report) => {
        if (!report.uploads || !report.uploads.length) {
          return;
        }

        preparedMediaReports.push({
          date: prepareShortMonthDate(report.reportDate),
          title: report.constructionStageName,
          cover: report.uploads[0].publicUrl,
          slides: report.uploads.map((media) => ({ url: media.publicUrl })),
        });
      });

      state.mediaReports = preparedMediaReports;
    },
    'set:lenders:timeline:tabs': function (state, tabs) {
      state.timelineTabs = tabs;
    },
    'set:timeline:tabs': function (state, units = []) {
      if (!units.length) {
        state.timelineTabs = [{
          id: 1488,
          label: 'Вся локация',
          labelEn: 'Entire location',
        }];

        return;
      }

      const preparedTabs = units.map(({
        userUnitId, unitName, unitId, unitNameEn,
      }) => ({
        id: userUnitId,
        reportUnitId: unitId,
        label: unitName,
        labelEn: unitNameEn,
      }));

      preparedTabs.push({
        id: 1488,
        label: 'Вся локация',
        labelEn: 'Entire location',
      });

      state.timelineTabs = preparedTabs;
    },
    'set:current:tab': function (state, tabId) {
      state.currentTab = state.timelineTabs.find((tab) => tab.id === tabId);
      localStorage.setItem(TIMELINE_CURRENT_TAB_ID, tabId);
    },
  },
  actions: {
    async initTimelines({
      dispatch,
    }) {
      await dispatch('setTimelineTabs');
      await dispatch('getTimeline');
    },

    async getTimeline({ dispatch, state, rootState }) {
      if (state.currentTab.id === 1488 || !rootState.user.isInvestor) {
        dispatch('getLocationTimeline');
      } else {
        dispatch('getTimelineByUnitId');
      }
    },

    async getReports({ dispatch, state, rootState }) {
      if (rootState.user.isInvestor) {
        if (state.currentTab.id === 1488) {
          dispatch('getMediaReportsByLocation', rootState.user.currentLocation.id);
        } else {
          dispatch('getMediaReportsByUnit');
        }
      } else {
        dispatch('getMediaReportsByLocation', state.currentTab.id);
      }
    },

    async getTimelineByUnitId({ commit, state }) {
      const timelinesByUnits = await timelineModel.getTimelinesByUnits(state.currentTab.id);
      commit('set:current:timeline', timelinesByUnits);
    },

    async getLocationTimeline({ commit, state, rootState }) {
      const currentLocationId = rootState.user.currentLocation.id;

      const locationTimeline = await timelineModel
        .getLocationTimeline(rootState.user.isInvestor
          ? currentLocationId
          : state.currentTab.id);
      commit('set:current:timeline:by:location', locationTimeline, true);
    },

    async getMediaReportsByUnit({ commit, state }) {
      const mediaReportsByUnit = await timelineModel
        .getMediaReportsByUnit(state.currentTab.reportUnitId);
      commit('set:media:reports:unit', mediaReportsByUnit);
    },

    async getMediaReportsByLocation({ commit }, locationId) {
      const mediaReportsByLocation = await timelineModel
        .getMediaReportsByLocation(locationId);
      commit('set:media:reports:unit', mediaReportsByLocation);
    },

    setTimelineTabs({ commit, dispatch, rootState }) {
      if (rootState.user.isInvestor) {
        const currentLocationUnits = rootState.user.units
          .filter((item) => item.locationId === rootState.user.currentLocation.id);
        commit('set:timeline:tabs', currentLocationUnits);
        dispatch('setCurrentTab', currentLocationUnits.length ? currentLocationUnits[0].userUnitId : 1488);
      } else {
        commit('set:lenders:timeline:tabs', LENDERS_TIMELINE_TABS);
        const tabIdIncluds = LENDERS_TIMELINE_TABS.some(
          (item) => item.id === Number(localStorage.getItem(TIMELINE_CURRENT_TAB_ID)),
        );

        dispatch('setCurrentTab', localStorage.getItem(TIMELINE_CURRENT_TAB_ID) === null || !tabIdIncluds
          ? LENDERS_TIMELINE_TABS[0].id
          : JSON.parse(localStorage.getItem(TIMELINE_CURRENT_TAB_ID)));
      }
    },

    setCurrentTab({ commit }, tabId) {
      commit('set:current:tab', tabId);
    },

    changeCurrentTab({ dispatch }, tabId) {
      dispatch('setCurrentTab', tabId);
      dispatch('getTimeline');
      dispatch('getReports');
    },
  },
};
