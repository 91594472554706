<template>
    <MainLayout
      class="dashboard"
      hideLogoText
    >
      <template #navigation>
        <header-menu />
        <personal-menu
          :user-name="userName"
          :menu-items="personalMenuItems"
          :user-pic="userAvatar"
          :user-roles="userRoles"
        />
        <burger-menu
          withPopup
          :popupMenuData="tabletBurgerMenuItems"
        >
          <template #popupContent>
            <lang-toggler
              :preselected-value="currentLocale"
              :locale-list="localesList"
              @lang-change="toggleLangHandler"
            />
            <user-manager
              :managerData="userManagerData"
            />
          </template>
        </burger-menu>
        <role-toggler
          v-if="isShowRoleToggler"
          :preselected-value="currentRole"
          @role-change="roleChangeHandler"
          @toggle-mobile-roles-menu="toggleRolesMenu"
        />
        <locations-list-mobile
          v-if="currentLocation && currentLocation.name"
          :current-location="currentLocation"
          @toggle-location-menu="toggleLocationMenu"
        />
        <lang-toggler
          class="dashboard__lang-toggler"
          :preselected-value="currentLocale"
          :locale-list="localesList"
          @lang-change="toggleLangHandler"
        />
        <burger-menu
          @burger-toggle="burgerToggleHandler"
          :is-close="triggerCloseBurger"
        />
      </template>
      <template #content>
        <div class="dashboard__outer">
          <slot name="outer-content"></slot>
        </div>
        <div class="dashboard__columns">
          <div class="dashboard__side-column">
            <mobile-menu-dropdown
              :class="{'dashboard__burger-menu' : isOpenLocationDropdown || isOpenRolesDropdown}"
              :is-open="isOpenMenuDropdown || isOpenLocationDropdown || isOpenRolesDropdown"
              :item-list="mobileMenuListItems"
              @item-click="mobileMenuClickHandler"
            >
              <template #secondaryContent>
                <lang-toggler
                  :preselected-value="currentLocale"
                  :locale-list="localesList"
                  @lang-change="toggleLangHandler"
                />
                <user-manager
                  :managerData="userManagerData"
                />
              </template>
            </mobile-menu-dropdown>
            <div class="dashboard__side-column-inner">
              <slot name="side-content"></slot>
            </div>
          </div>
          <div
            class="dashboard__main-column"
            :class="{
              'dashboard__main-column--clip': overflowClip,
            }"
          >
            <slot name="main-content"></slot>
          </div>
        </div>
      </template>
    </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';
import BurgerMenu from '@/components/BurgerMenu/BurgerMenu.vue';
import MobileMenuDropdown from '@/components/MobileMenuDropdown/MobileMenuDropdown.vue';
import LocationsListMobile from '@/ui-kit/LocationsListMobile/LocationsListMobile.vue';
import RoleToggler from '@/components/RoleToggler/RoleToggler.vue';
import HeaderMenu from '@/components/HeaderMenu/HeaderMenu.vue';
import PersonalMenu from '@/components/PersonalMenu/PersonalMenu.vue';
import UserManager from '@/components/UserManager/UserManager.vue';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { BURGER_MENU_ITEMS_LIST, TABLET_BURGER_MENU_ITEMS_LIST } from '@/constants/common';
import LangToggler from '@/components/LangToggler/LangToggler.vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'DashboardLayout',
  components: {
    LangToggler,
    MainLayout,
    HeaderMenu,
    PersonalMenu,
    BurgerMenu,
    RoleToggler,
    MobileMenuDropdown,
    LocationsListMobile,
    UserManager,
  },
  props: {
    overflowClip: {
      default: false,
      type: Boolean,
    },
    locationsList: {
      type: Array,
      default: () => [],
    },
    currentLocation: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['locationChange'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    const userState = store.state.user;
    const currentRole = computed(() => userState.currentRole);
    const currentLocale = computed(() => userState.currentLocale);
    const isShowRoleToggler = computed(() => userState.roles && userState.roles.length > 1);
    const personalMenuItems = computed(() => userState.personalMenuItems);
    const userName = computed(() => userState.profileSettings.name);
    const userAvatar = computed(() => userState.profileSettings.avatar);
    const userRoles = computed(() => userState.profileSettings.roles);
    const localesList = computed(() => userState.allLocales);

    console.log(localesList, 'localesList');
    const userManagerData = computed(() => userState.managerData);

    const tabletBurgerMenuItems = ref(TABLET_BURGER_MENU_ITEMS_LIST);
    const isOpenMenuDropdown = ref(false);
    const isOpenLocationDropdown = ref(false);
    const isOpenRolesDropdown = ref(false);
    const mobileMenuListItems = ref([]);
    const triggerCloseBurger = ref(false);
    const rolesList = ref([
      {
        label: t('investorRole'),
        id: 'INVESTOR',
      },
      {
        label: t('lender'),
        id: 'LENDER',
      },
    ]);

    const initDashboardLayout = async () => {
      await store.dispatch('user/initUserLocale');
    };

    initDashboardLayout();

    const burgerToggleHandler = () => {
      mobileMenuListItems.value = BURGER_MENU_ITEMS_LIST;
      isOpenMenuDropdown.value = !isOpenMenuDropdown.value;
      isOpenLocationDropdown.value = false;
      isOpenRolesDropdown.value = false;
    };

    const mobileMenuClickHandler = async (item) => {
      if (isOpenLocationDropdown.value) {
        emit('location-change', item);
        return;
      }

      if (isOpenRolesDropdown.value) {
        await store.dispatch('user/setCurrentRole', item);
        return;
      }

      if (item === 'logout') {
        await store.dispatch('user/logout');
        await router.push('/login');
      }
    };

    const toggleLocationMenu = () => {
      mobileMenuListItems.value = props.locationsList;
      isOpenLocationDropdown.value = !isOpenLocationDropdown.value;
      triggerCloseBurger.value = !triggerCloseBurger.value;
      isOpenMenuDropdown.value = false;
      isOpenRolesDropdown.value = false;
    };

    const toggleRolesMenu = () => {
      mobileMenuListItems.value = rolesList.value;
      isOpenRolesDropdown.value = !isOpenRolesDropdown.value;
      triggerCloseBurger.value = !triggerCloseBurger.value;
      isOpenMenuDropdown.value = false;
      isOpenLocationDropdown.value = false;
    };

    const locationChangeHandler = (id) => {
      emit('locationChange', id);
    };

    const roleChangeHandler = (role) => {
      store.dispatch('user/setCurrentRole', role);
    };

    const toggleLangHandler = (newLocale) => {
      store.dispatch('user/setLocale', newLocale);
    };

    return {
      isOpenMenuDropdown,
      isOpenLocationDropdown,
      mobileMenuListItems,
      triggerCloseBurger,
      currentRole,
      rolesList,
      isOpenRolesDropdown,
      isShowRoleToggler,
      currentLocale,
      userName,
      userAvatar,
      localesList,
      personalMenuItems,
      burgerToggleHandler,
      mobileMenuClickHandler,
      toggleLocationMenu,
      locationChangeHandler,
      roleChangeHandler,
      toggleRolesMenu,
      toggleLangHandler,
      userManagerData,
      tabletBurgerMenuItems,
      userRoles,
    };
  },
};
</script>

<style lang="scss">
$height-of-top-blocks: 162px;
$height-of-footer: 43px;
.dashboard {
  &__burger-menu {
    .mobile-menu-dropdown {
      &__secondary-content {
        display: none;
      }
    }
  }

  &__columns {
    position: relative;
    display: flex;
    padding: 32px 24px 0 18px;
    min-height: 81vh;
  }

  &__side-column {
    position: absolute;
    width: 260px;
    height: 100%;
    flex-shrink: 0;

    .user-actives {
      margin-top: 30px;
      margin-bottom: 24px;
    }

    &-inner {
      position: sticky;
      top: 35px;
      height: calc(100vh - $height-of-top-blocks - $height-of-footer + 20px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__main-column {
    display: block;
    padding-left: 260px;
    overflow: hidden;
    flex-grow: 1;

    &--clip {
      overflow: clip;
    }
  }

  &__lang-toggler {
    order: 2;
    margin-right: 44px;
  }

  &__outer {
    border-bottom: 1px solid $grey200;

    .tabs {
      padding-left: 18px;

      &__list {
        border: none;
      }
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .dashboard {
    &__columns {
      flex-direction: column;
      padding: 0;
    }

    &__main-column {
      padding: 55px 24px 32px 24px;
    }

    &__side-column {
      position: initial;
      width: 100%;
      height: initial;
      border-bottom: 1px solid $grey200;

      .user-actives {
        display: none !important;
      }

      .user-manager {
        display: none !important;
      }

      &-inner {
        max-width: 100%;
        position: absolute;
        height: initial;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }

    &__outer {
      background: $grey100;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .dashboard {
    &__outer {
      display: none;
    }

    &__main-column {
      padding: 74px 20px 32px;
    }

    &__side-column {
      position: sticky;
      top: 44px;
      z-index: 3;

      .mobile-menu-dropdown {
        &__secondary-content {
          .user-manager {
            display: flex!important;
          }
        }
      }
    }
  }
}
</style>
