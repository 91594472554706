<template>
  <content-layout
    class="invalid-page"
  >
    <template #main-content>
      <div class="invalid-page__main-block">
        <div class="invalid-page__container">
          <img
            class="invalid-page__img"
            alt=""
            src="@/assets/404.svg"
          >
          <div class="invalid-page__text-area">
            <title-main
              class="invalid-page__title"
              text="Такой страницы нет"
            />
            <p class="invalid-page__text">
              Пожалуйста, проверьте введенный адрес или вернитесь на главную
            </p>
            <router-link
              to="/"
              class="invalid-page__router-link"
            >
              <primary-button
                class="invalid-page__button"
                label="Вернуться на главную"
                green
              />
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </content-layout>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import ContentLayout from '@/layouts/ContentLayout.vue';

import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
import PrimaryButton from '@/ui-kit/PrimaryButton/PrimaryButton.vue';
import { useRoute } from 'vue-router';

export default {
  name: 'InvalidPage',
  components: {
    TitleMain,
    ContentLayout,
    PrimaryButton,
  },
  setup() {
    const store = useStore();
    const userState = store.state.user;
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name, labelEn: location.nameEn })));
    const currentUserLocation = computed(() => userState.currentLocation.id);

    const initInvalidPageData = async () => {
      store.dispatch('base/setCurrentPath', useRoute());
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/getUserUnitsFullInfo');
      if (!userState.profileSettings.name) {
        await store.dispatch('user/getUserProfileSettings');
      }
      await store.dispatch('user/getFaq');
    };

    initInvalidPageData();

    return {
      userLocations,
      currentUserLocation,
    };
  },
};
</script>

<style scoped lang="scss">
.invalid-page {

  &__main-block {
    position: relative;
    width: 100%;
    height: 55vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__text-area {
    width: 353px;
    margin-left: 34px;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: $font-size-title-large;
    font-weight: $font-weight-bold;
    line-height: 40px;
    color: $main-black;
  }

  &__text {
    margin: 12px 0 0 0;
    padding: 0;
    font-size: $font-size-medium;
    font-weight: $font-weight-regular;
    line-height: 22px;
    color: $main-black;
  }

  &__button {
    margin-top: 24px;
    font-weight: $font-weight-bold;
    font-size: $font-size-regular;
    line-height: 18px;
    width: 190px;
  }

  &__router-link {
    text-decoration: none;
  }
}

@media screen and (max-width: 980px) {
  .invalid-page {
    &__img {
      width: 202px;
      height: 139px;
    }

    &__title {
      line-height: 32px;
      font-size: $font-size-title-mid-large;
    }

    &__text {
      margin-top: 8px;
    }
  }
}

@media screen and (max-width: 650px) {
  .invalid-page {

    &__container {
      flex-direction: column;
    }

    &__text-area {
      margin: 40px 0 0 0;
      width: 100%;
    }
  }
}

@media screen and (max-width: 450px) {
  .invalid-page {

    &__title {
      line-height: 24px;
    }

    &__text {
      margin-top: 12px;
      font-size: $font-size-small;
      line-height: 18px;
    }

    &__button {
      margin-top: 24px;
      font-size: $font-size-small;
    }
  }
}
</style>
