import documentsModel from '@/models/documents';
import { prepareNumericDate } from '@/helpers/datesAndTime';

export default {
  namespaced: true,
  state: () => ({
    userDocuments: [],
    documents: [],
    currentLocationDocuments: [],
    filteredDocuments: [],
    currentFilters: {},
    hasUnsignDocuments: false,
  }),
  mutations: {
    'set:user:documents': function (state, documents) {
      state.userDocuments = documents && documents.length
        ? documents.map(({
          id,
          createDate,
          category,
          location,
          unit,
          description,
          descriptionEn,
          number,
          status,
          uploadsId,
        }) => ({
          id,
          rawDate: createDate || null,
          date: createDate ? prepareNumericDate(createDate, {}, '.') : null,
          category: category || null,
          location: location || { name: unit.locationName, id: unit.locationId } || null,
          locationEn: unit.locationNameEn,
          unit: unit || null,
          description: description || null,
          descriptionEn: descriptionEn || null,
          status: status || null,
          docNum: number || null,
          uploadsId: uploadsId || null,
        }))
        : [];
    },
    'set:documents': function (state, documents) {
      state.documents = documents && documents.length
        ? documents
        : [];
    },
    'set:location:documents': function (state, { currentLocationDocuments }) {
      state.currentLocationDocuments = currentLocationDocuments && currentLocationDocuments.length
        ? currentLocationDocuments.map(({
          id,
          createDate,
          category,
          location,
          unit,
          description,
          number,
          status,
          uploadsId,
        }) => ({
          id,
          rawDate: createDate || null,
          date: createDate ? prepareNumericDate(createDate, {}, '.') : null,
          category: category || null,
          location: location || null,
          unit: unit || null,
          description: description || null,
          status: status || null,
          docNum: number || null,
          uploadsId: uploadsId || null,
        }))
        : [];
    },
    'set:filtered:documents': function (state, documents) {
      state.filteredDocuments = [...documents];
    },
    'set:current:filters': function (state, filters) {
      state.currentFilters = { ...filters };
    },
    'set:unsign:flag': function (state, hasUnsign) {
      state.hasUnsignDocuments = hasUnsign;
    },
  },
  actions: {
    async initDocuments({ dispatch, commit, state }) {
      await dispatch('getUserDocuments');
      await dispatch('getDocuments');
      await dispatch('getLocationDocuments');
      const allDocs = [...state.userDocuments, ...state.currentLocationDocuments];
      commit('set:unsign:flag', allDocs.some((doc) => doc.status.id === 1));
      commit('set:filtered:documents', allDocs);
    },
    async getUserDocuments({ commit }) {
      const resp = await documentsModel.getUserDocuments();
      if (resp.ok) {
        commit('set:user:documents', resp.data);
      }
    },
    async getDocuments({ commit }) {
      const resp = await documentsModel.getDocuments();
      if (resp.ok) {
        commit('set:documents', resp.data);
      }
    },
    async getLocationDocuments({ commit, rootState }) {
      const resp = await documentsModel.getLocationDocuments();
      if (resp.ok) {
        commit('set:location:documents', {
          documents: resp.data,
          currentLocationDocuments: resp.data
            .filter((document) => document.location.id === rootState.user.currentLocation.id),
        });
      }
    },
    async filterDocuments({ commit, state }, { key, options } = { key: null, options: [] }) {
      const allDocs = [...state.currentLocationDocuments, ...state.userDocuments];
      const currentFilters = { ...state.currentFilters };
      if (!options.length) {
        delete currentFilters[key];
        commit('set:current:filters', currentFilters);
      } else {
        currentFilters[key] = [...options];
        commit('set:current:filters', currentFilters);
      }

      const filtersKeys = Object.keys(currentFilters);
      if (!filtersKeys.length) {
        commit('set:filtered:documents', allDocs);
        return;
      }

      commit('set:filtered:documents', allDocs.filter((doc) => {
        let answer = true;
        filtersKeys.forEach((filterKey) => {
          const currentKeyIds = currentFilters[filterKey].map((item) => ({ id: item.id }));
          if (!doc[filterKey]
            || currentKeyIds.findIndex(({ id }) => doc[filterKey].id === id) < 0) {
            answer = false;
          }
        });
        return answer;
      }));
    },
    async downloadDoc(context, id) {
      await documentsModel.downloadDocument(id);
    },
    async uploadDoc({ dispatch }, payload) {
      const resp = await documentsModel.uploadDocument(payload);
      if (resp.ok) {
        dispatch('getUserDocuments');
      }
      return resp.ok;
    },
  },
};
