<template>
  <div
    class="tabs"
    :class="{
      'tabs--flat': flat,
    }"
  >
    <div class="tabs__btn-area">
      <div
        class="tabs__list"
        role="tablist"
      >
        <button
          v-for="tab in list"
          :key="tab.id"
          class="tabs__list-item"
          :class="{
            'tabs__list-item--active': tab.id === activeTabId,
            'tabs__list-item--soon': tab.soon,
          }"
          :style="tab.soon ? { '--soon-text': locale === 'en' ? `'soon'` : `'скоро'` } : {}"
          role="tab"
          tabindex="0"
          @click="selectTab(tab)"
        >
          {{ locale === "en" ? tab.labelEn : tab.label }}
        </button>
      </div>
      <div class="tabs__buttons-list">
        <slot name="buttons">
        </slot>
      </div>
    </div>
    <div
      v-if="hasContent"
      class="tabs__content"
    >
      <div
        v-for="tab in list"
        :key="`tabs_slot_${tab.id}`"
        class="tabs__content-slot"
        :class="{
          'tabs__content-slot--active': tab.id === activeTabId,
        }"
      >
        <slot
          :name="tab.id"
        >
        </slot>
      </div>
    </div>
    <div class="tabs__buttons-list--mobile">
      <slot name="buttons">
      </slot>
    </div>
  </div>
</template>

<script>
import { watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'ui-tabs',
  props: {
    list: {
      type: Array,
      required: true,
    },
    preselectedTabId: {
      type: String || Number,
      default: '',
    },
    hasContent: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['tabChange'],
  setup(props, { emit }) {
    const { locale } = useI18n();
    const activeTabId = ref(props.preselectedTabId);
    watch(() => props.preselectedTabId, (curr) => {
      activeTabId.value = curr;
    });

    const selectTab = (tab) => {
      activeTabId.value = tab.id;
      emit('tabChange', tab.id);
    };

    return {
      activeTabId,
      selectTab,
      locale,
    };
  },
};
</script>

<style lang="scss">
.tabs {
  display: flex;
  flex-direction: column;

  &__btn-area {
    display: flex;
    justify-content: space-between;
  }

  &__buttons-list {
    &--mobile {
      display: none;
    }
  }

  &__content {
    &-slot {
      display: none;

      &--active {
        display: block;
      }
    }
  }

  &__list {
    display: flex;
    padding: 12px 0;
    gap: 0 16px;
    border-top: 1px solid $grey300;
    border-bottom: 1px solid $grey300;
    color: $main-black;
    font-weight: $font-weight-medium;
    font-size: $font-size-regular;

    &-item {
      display: flex;
      align-items: center;
      margin-right: 2px;
      line-height: 18px;
      border: none;
      border-radius: 12px;
      background-color: transparent;
      font-size: $font-size-regular;
      font-weight: $font-weight-medium;
      cursor: pointer;

      &--active {
        padding: 3px 9px;
        color: $white;
        background-color: $blue400;
      }

      &--soon {
        color: $grey700;
        pointer-events: none;
        cursor: default;

        &:before {
          content: var(--soon-text, 'скоро');
          display: inline-block;
          padding: 4px 2px;
          margin-right: 4px;
          background-color: $grey400;
          border-radius: 8px;
          color: #fff;
          font-weight: 700;
          font-size: 7px;
          text-transform: uppercase;
          line-height: 3px;
        }

        &:hover {
          cursor: default;
          opacity: 1;
        }
      }
    }
  }

  &--flat {
    .tabs {
      &__btn-area {
        border-bottom: 1px solid $grey200;
      }

      &__list {
        padding: 0;
        border-top: none;
        border-bottom: none;

        &-item {
          padding: 8px 0;
          margin-bottom: -1px;
          color: $grey800;
          border-bottom: 2px solid transparent;
          border-radius: 0;

          &--active {
            background-color: transparent;
            color: $blue500;
            border-bottom-color: $blue500;
          }

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .tabs {
    &__list {
      &-item {
        padding: 10px 16px;
        border-radius: 32px;
        font-size: $font-size-regular;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .tabs {
    &--flat {
      .tabs {
        &__buttons-list {
          display: none;
        }

        &__btn-area {
          border-bottom: none;
          overflow-x: auto;

          &::-webkit-scrollbar {
            display: none;
          }
          -ms-overflow-style: none;
          scrollbar-width: none;

          flex-direction: column;
        }
        &__list {
          gap: 0;
          border: none;
          flex-direction: row-reverse;
          justify-content: flex-end;

          &-item {
            margin: 0;
            padding: 11px 12px;
            border: 1px solid;
            border-color: $grey200;
            border-left: none;
            color: $main-black;

            &:first-child {
              border-radius: 0 29px 29px 0;
            }
            &:last-child {
              border-left: 1px solid $grey200;
              border-radius: 29px 0 0 29px;
            }

            &--active {
              color: $blue500;
              border-color: $blue500 !important;
              border-left: 1px solid $blue500;
            }
          }
        }
      }
    }
  }

  .tabs__buttons-list--mobile {
    margin-top: 24px;
    display: block;
  }
}

</style>
