<template>
  <div class="documents-table">
    <div
      class="documents-table__controls"
    >
      <secondary-button
        dashed
        :label="t('date')"
        @click="sortByDate"
      >
        <template #iconBefore>
          <div
            class="documents-table__sort-icon"
            :class="{
              'documents-table__sort-icon--desc': sortOrder === 'desc',
            }"
          >
            <svg width="7" height="8" viewBox="0 0 7 8"
                  xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M3.5 8L7 4.5L6.299 3.8L4 6.098L4 2.62268e-07L3 3.49691e-07L3
                  6.098L0.701 3.8L-3.0598e-07 4.5L3.5 8Z"/>
            </svg>
          </div>
        </template>
      </secondary-button>
      <filter-dropdown
        v-if="categoryList && categoryList.length"
        :filter-name="t('category')"
        is-multiple-choice
        :filter-values="categoryList"
        @filter-select="categorySelectHandler"
      >
        <template #icon>
          <svg width="9" height="8" viewBox="0 0 9 8"
                fill="none" xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7.58989 0H0.879307C0.708949 0.000134153 0.542294 0.0497129
            0.399565 0.142719C0.256837 0.235726 0.144173 0.368161 0.075246
            0.523951C0.00631945 0.679742 -0.0159059 0.85219 0.0112674 1.02037C0.0384408
            1.18854 0.113844 1.34522 0.228327 1.47137L3.13656 4.6698V7.68627C3.13658
            7.74523 3.15323 7.803 3.18458 7.85294C3.21593 7.90288 3.26072 7.94297 3.31381
            7.96862C3.35625 7.98955 3.40297 8.00029 3.45028 7.99999C3.52159 7.99994
            3.59075 7.9756 3.64636 7.93098L4.2346 7.46039L5.21499 6.67607C5.25168
            6.6467 5.28129 6.60946 5.30166 6.56711C5.32202 6.52475 5.33261 6.47836 5.33264
            6.43137V4.6698L8.24087 1.47137C8.35535 1.34522 8.43075 1.18854 8.45793
            1.02037C8.4851 0.85219 8.46288 0.679742 8.39395 0.523951C8.32502 0.368161
            8.21236 0.235726 8.06963 0.142719C7.9269 0.0497129 7.76025 0.000134153
            7.58989 0Z" fill="#89909E"/>
          </svg>

        </template>
      </filter-dropdown>
      <filter-dropdown
        v-if="locationList && locationList.length"
        :filter-name="t('location')"
        is-multiple-choice
        :filter-values="locationList"
        @filter-select="locationSelectHandler"
      >
        <template #icon>
          <svg width="9" height="8" viewBox="0 0 9 8"
                fill="none" xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7.58989 0H0.879307C0.708949 0.000134153 0.542294 0.0497129
            0.399565 0.142719C0.256837 0.235726 0.144173 0.368161 0.075246
            0.523951C0.00631945 0.679742 -0.0159059 0.85219 0.0112674 1.02037C0.0384408
            1.18854 0.113844 1.34522 0.228327 1.47137L3.13656 4.6698V7.68627C3.13658
            7.74523 3.15323 7.803 3.18458 7.85294C3.21593 7.90288 3.26072 7.94297 3.31381
            7.96862C3.35625 7.98955 3.40297 8.00029 3.45028 7.99999C3.52159 7.99994
            3.59075 7.9756 3.64636 7.93098L4.2346 7.46039L5.21499 6.67607C5.25168
            6.6467 5.28129 6.60946 5.30166 6.56711C5.32202 6.52475 5.33261 6.47836 5.33264
            6.43137V4.6698L8.24087 1.47137C8.35535 1.34522 8.43075 1.18854 8.45793
            1.02037C8.4851 0.85219 8.46288 0.679742 8.39395 0.523951C8.32502 0.368161
            8.21236 0.235726 8.06963 0.142719C7.9269 0.0497129 7.76025 0.000134153
            7.58989 0Z" fill="#89909E"/>
          </svg>

        </template>
      </filter-dropdown>
      <filter-dropdown
        v-if="unitList && unitList.length"
        :filter-name="t('гтше')"
        is-multiple-choice
        :filter-values="unitList"
        @filter-select="categorySelectHandler"
      >
        <template #icon>
          <svg width="9" height="8" viewBox="0 0 9 8"
                fill="none" xmlns="http://www.w3.org/2000/svg"
          >

            <path d="M7.58989 0H0.879307C0.708949 0.000134153 0.542294 0.0497129
            0.399565 0.142719C0.256837 0.235726 0.144173 0.368161 0.075246
            0.523951C0.00631945 0.679742 -0.0159059 0.85219 0.0112674 1.02037C0.0384408
            1.18854 0.113844 1.34522 0.228327 1.47137L3.13656 4.6698V7.68627C3.13658
            7.74523 3.15323 7.803 3.18458 7.85294C3.21593 7.90288 3.26072 7.94297 3.31381
            7.96862C3.35625 7.98955 3.40297 8.00029 3.45028 7.99999C3.52159 7.99994
            3.59075 7.9756 3.64636 7.93098L4.2346 7.46039L5.21499 6.67607C5.25168
            6.6467 5.28129 6.60946 5.30166 6.56711C5.32202 6.52475 5.33261 6.47836 5.33264
            6.43137V4.6698L8.24087 1.47137C8.35535 1.34522 8.43075 1.18854 8.45793
            1.02037C8.4851 0.85219 8.46288 0.679742 8.39395 0.523951C8.32502 0.368161
            8.21236 0.235726 8.06963 0.142719C7.9269 0.0497129 7.76025 0.000134153
            7.58989 0Z" fill="#89909E"/>
          </svg>

        </template>
      </filter-dropdown>
    </div>
    <div
      class="documents-table__documents"
    >
      <div class="documents-table__inner">
        <div class="documents-table__header">
          <div class="documents-table__row">
            <div class="documents-table__cell documents-table__cell--date">
              <div class="documents-table__cell-content">
                <secondary-button
                  dashed
                  uppercase
                  :label="t('date')"
                  @click="sortByDate"
                  class="documents-table__desktop-button"
                >
                  <template #iconBefore>
                    <div
                      class="documents-table__sort-icon"
                      :class="{
                        'documents-table__sort-icon--desc': sortOrder === 'desc',
                      }"
                    >
                      <svg width="7" height="8" viewBox="0 0 7 8"
                           xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M3.5 8L7 4.5L6.299 3.8L4 6.098L4 2.62268e-07L3 3.49691e-07L3
                            6.098L0.701 3.8L-3.0598e-07 4.5L3.5 8Z"/>
                      </svg>
                    </div>
                  </template>
                </secondary-button>
                <div class="documents-table__tablet-content">
                  {{ t('date') }}
                </div>
              </div>
            </div>
            <div class="documents-table__cell documents-table__cell--category">
              <div class="documents-table__cell-content">
                <filter-dropdown
                  v-if="categoryList && categoryList.length"
                  :filter-name="t('category')"
                  is-multiple-choice
                  :filter-values="categoryList"
                  @filter-select="categorySelectHandler"
                  class="documents-table__desktop-button"
                >
                  <template #icon>
                    <svg width="9" height="8" viewBox="0 0 9 8"
                         fill="none" xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.58989 0H0.879307C0.708949 0.000134153 0.542294 0.0497129
                      0.399565 0.142719C0.256837 0.235726 0.144173 0.368161 0.075246
                      0.523951C0.00631945 0.679742 -0.0159059 0.85219 0.0112674 1.02037C0.0384408
                      1.18854 0.113844 1.34522 0.228327 1.47137L3.13656 4.6698V7.68627C3.13658
                      7.74523 3.15323 7.803 3.18458 7.85294C3.21593 7.90288 3.26072 7.94297 3.31381
                      7.96862C3.35625 7.98955 3.40297 8.00029 3.45028 7.99999C3.52159 7.99994
                      3.59075 7.9756 3.64636 7.93098L4.2346 7.46039L5.21499 6.67607C5.25168
                      6.6467 5.28129 6.60946 5.30166 6.56711C5.32202 6.52475 5.33261 6.47836 5.33264
                      6.43137V4.6698L8.24087 1.47137C8.35535 1.34522 8.43075 1.18854 8.45793
                      1.02037C8.4851 0.85219 8.46288 0.679742 8.39395 0.523951C8.32502 0.368161
                      8.21236 0.235726 8.06963 0.142719C7.9269 0.0497129 7.76025 0.000134153
                      7.58989 0Z" fill="#89909E"/>
                    </svg>

                  </template>
                </filter-dropdown>
                <div class="documents-table__tablet-content">
                  {{ t('category') }}
                </div>
              </div>
            </div>
            <div class="documents-table__cell documents-table__cell--location">
              <div class="documents-table__cell-content">
                <filter-dropdown
                  v-if="locationList && locationList.length"
                  :filter-name="isInvestor ? 'Локация' : 'Проект'"
                  is-multiple-choice
                  :filter-values="locationList"
                  @filter-select="locationSelectHandler"
                  class="documents-table__desktop-button"
                >
                  <template #icon>
                    <svg width="9" height="8" viewBox="0 0 9 8"
                         fill="none" xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.58989 0H0.879307C0.708949 0.000134153 0.542294 0.0497129
                      0.399565 0.142719C0.256837 0.235726 0.144173 0.368161 0.075246
                      0.523951C0.00631945 0.679742 -0.0159059 0.85219 0.0112674 1.02037C0.0384408
                      1.18854 0.113844 1.34522 0.228327 1.47137L3.13656 4.6698V7.68627C3.13658
                      7.74523 3.15323 7.803 3.18458 7.85294C3.21593 7.90288 3.26072 7.94297 3.31381
                      7.96862C3.35625 7.98955 3.40297 8.00029 3.45028 7.99999C3.52159 7.99994
                      3.59075 7.9756 3.64636 7.93098L4.2346 7.46039L5.21499 6.67607C5.25168
                      6.6467 5.28129 6.60946 5.30166 6.56711C5.32202 6.52475 5.33261 6.47836 5.33264
                      6.43137V4.6698L8.24087 1.47137C8.35535 1.34522 8.43075 1.18854 8.45793
                      1.02037C8.4851 0.85219 8.46288 0.679742 8.39395 0.523951C8.32502 0.368161
                      8.21236 0.235726 8.06963 0.142719C7.9269 0.0497129 7.76025 0.000134153
                      7.58989 0Z" fill="#89909E"/>
                    </svg>

                  </template>
                </filter-dropdown>
                <div class="documents-table__tablet-content">
                  {{ isInvestor ? t('location') : t('project') }}
                </div>
              </div>
            </div>
            <div
              v-if="unitList && unitList.length"
              class="documents-table__cell documents-table__cell--unit"
            >
              <div class="documents-table__cell-content">
                <filter-dropdown
                  :filter-name="t('unit')"
                  is-multiple-choice
                  :filter-values="unitList"
                  @filter-select="unitSelectHandler"
                  class="documents-table__desktop-button"
                >
                  <template #icon>
                    <svg width="9" height="8" viewBox="0 0 9 8"
                         fill="none" xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.58989 0H0.879307C0.708949 0.000134153 0.542294 0.0497129
                      0.399565 0.142719C0.256837 0.235726 0.144173 0.368161 0.075246
                      0.523951C0.00631945 0.679742 -0.0159059 0.85219 0.0112674 1.02037C0.0384408
                      1.18854 0.113844 1.34522 0.228327 1.47137L3.13656 4.6698V7.68627C3.13658
                      7.74523 3.15323 7.803 3.18458 7.85294C3.21593 7.90288 3.26072 7.94297 3.31381
                      7.96862C3.35625 7.98955 3.40297 8.00029 3.45028 7.99999C3.52159 7.99994
                      3.59075 7.9756 3.64636 7.93098L4.2346 7.46039L5.21499 6.67607C5.25168
                      6.6467 5.28129 6.60946 5.30166 6.56711C5.32202 6.52475 5.33261 6.47836 5.33264
                      6.43137V4.6698L8.24087 1.47137C8.35535 1.34522 8.43075 1.18854 8.45793
                      1.02037C8.4851 0.85219 8.46288 0.679742 8.39395 0.523951C8.32502 0.368161
                      8.21236 0.235726 8.06963 0.142719C7.9269 0.0497129 7.76025 0.000134153
                      7.58989 0Z" fill="#89909E"/>
                    </svg>

                  </template>
                </filter-dropdown>
                <div class="documents-table__tablet-content">
                  {{ t('unit') }}
                </div>
              </div>
            </div>
            <div class="documents-table__cell documents-table__cell--unit-num">
              <div class="documents-table__cell-content">
                {{ t('room') }}
              </div>
            </div>
            <div class="documents-table__cell documents-table__cell--description">
              <div class="documents-table__cell-content">
                {{ t('description') }}
              </div>
            </div>
            <div class="documents-table__cell documents-table__cell--status">
              <div class="documents-table__cell-content">
                {{ t('status') }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!isStub"
          class="documents-table__body"
        >
          <div
            v-for="(document, id) in filteredList"
            :key="`${document.date}_${id}`"
            class="documents-table__row"
            :class="{
              'documents-table__row--state-alert' : document.status === -1,
            }"
          >
            <div class="documents-table__cell documents-table__cell--date">
              <div class="documents-table__cell-content">
                  {{ document.date }}
              </div>
            </div>
            <div class="documents-table__cell documents-table__cell--category">
              <div class="documents-table__cell-content">
                <ui-label
                  class="documents-table__cell-label"
                  :label="(locale === 'en' ? document.category.nameEn : document.category.name)"
                  :background="CATEGORY_COLORS[document.category.id]"
                />
              </div>
            </div>
            <div class="document-table__cell documents-table__cell--location">
              <div class="documents-table__cell-content">
                {{ document.location ? (locale === 'en' ? document.locationEn : document.location.name) : '' }}
              </div>
            </div>
            <div
              v-if="unitList && unitList.length"
              class="documents-table__cell documents-table__cell--unit"
            >
              <div class="documents-table__cell-content">
                {{ document.unit ? (locale === 'en' ? document.unit.nameEn : document.unit.name) : '' }}
              </div>
            </div>
            <div class="documents-table__cell documents-table__cell--unit-num">
              <div class="documents-table__cell-content">
                {{ document.docNum }}
              </div>
            </div>
            <div class="documents-table__cell documents-table__cell--description">
              <div class="documents-table__cell-content">
                {{ locale === 'en' ? document.descriptionEn : document.description }}
              </div>
            </div>
            <div class="documents-table__cell documents-table__cell--status">
              <div class="documents-table__cell-content">
                <div
                  class="documents-table__cell-wrapper"
                >
                  <svg
                    v-if="document.status.id === 1"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="documents-table__icon"
                  >
                    <path
                      d="M16 9C16 13.4183 12.4183 17 8 17C3.58172 17 0 13.4183 0 9C0
                      4.58172 3.58172 1 8 1C12.4183 1 16 4.58172 16 9Z"
                      fill="#F48575"
                    />
                    <path
                      d="M8.8 13C8.8 13.4418 8.44183 13.8 8 13.8C7.55817 13.8 7.2
                      13.4418 7.2 13C7.2 12.5582 7.55817 12.2 8 12.2C8.44183
                      12.2 8.8 12.5582 8.8 13Z"
                      fill="#F5F5F5"
                    />
                    <path
                      d="M7.2 5.8C7.2 5.35817 7.55817 5 8 5C8.44183 5 8.8 5.35817
                      8.8 5.8V10.6C8.8 11.0418 8.44183 11.4 8 11.4C7.55817
                      11.4 7.2 11.0418 7.2 10.6V5.8Z"
                      fill="#F5F5F5"
                    />
                  </svg>
                  <svg
                    v-if="document.status.id === 13"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="documents-table__icon"
                  >
                    <rect
                      y="1"
                      width="16"
                      height="16"
                      rx="8"
                      fill="#658EEA"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.206 6.13674C8.44221 5.81258 7.58052 5.8041 6.81049
                      6.11315C6.58244 6.20468 6.32312 6.09463 6.23128
                      5.86733C6.13945 5.64003 6.24987 5.38158 6.47793
                      5.29005C7.46701 4.89308 8.57379 4.90398 9.55482
                      5.32034C10.5359 5.73671 11.3107 6.52442 11.709
                      7.5102C12.1072 8.496 12.0964 9.59908 11.6786
                      10.5768C11.4207 11.1804 11.0209 11.7056 10.52
                      12.1127H11.5409C11.7868 12.1127 11.9861 12.3113
                      11.9861 12.5563C11.9861 12.8014 11.7868 13 11.5409
                      13H9.31515C9.06929 13 8.87 12.8014 8.87 12.5563V12.3226C8.86982
                      12.3158 8.86982 12.309 8.87 12.3023V10.338C8.87 10.0929 9.06929
                      9.8943 9.31515 9.8943C9.56101 9.8943 9.76031 10.0929 9.76031
                      10.338V11.5726C10.2429 11.2399 10.6259 10.7758 10.8594 10.2292C11.1847
                      9.46797 11.1932 8.6092 10.8832 7.84167C10.573 7.07417 9.96979 6.4609
                      9.206 6.13674ZM5.14404 6.41583C5.38989 6.41583 5.5892 6.61446 5.5892
                      6.8595V6.86394C5.5892 7.10897 5.38989 7.30761 5.14404 7.30761C4.89819
                      7.30761 4.69889 7.10897 4.69889 6.86394V6.8595C4.69889 6.61446 4.89819
                      6.41583 5.14404 6.41583ZM4.44515 8.11957C4.691 8.11957 4.89031 8.3182
                      4.89031 8.56324V8.56768C4.89031 8.81272 4.691 9.01135 4.44515
                      9.01135C4.1993 9.01135 4 8.81272 4 8.56768V8.56324C4 8.3182 4.1993
                      8.11957 4.44515 8.11957ZM4.69889 9.93867C4.94474 9.93867 5.14404
                      10.1373 5.14404 10.3823V10.3868C5.14404 10.6318 4.94474 10.8304
                      4.69889 10.8304C4.45304 10.8304 4.25374 10.6318 4.25374
                      10.3868V10.3823C4.25374 10.1373 4.45304 9.93867 4.69889
                      9.93867ZM5.82513 11.3895C6.07098 11.3895 6.27028 11.5881
                      6.27028 11.8331V11.8376C6.27028 12.0826 6.07098 12.2813
                      5.82513 12.2813C5.57928 12.2813 5.37997 12.0826 5.37997
                      11.8376V11.8331C5.37997 11.5881 5.57928 11.3895 5.82513
                      11.3895ZM7.53454 12.0859C7.7804 12.0859 7.97969 12.2846
                      7.97969 12.5296V12.5341C7.97969 12.7791 7.7804 12.9777
                      7.53454 12.9777C7.28868 12.9777 7.08939 12.7791 7.08939
                      12.5341V12.5296C7.08939 12.2846 7.28868 12.0859 7.53454
                      12.0859Z"
                      fill="#FAFBFB"
                    />
                  </svg>
                  <svg
                    v-if="document.status.id === 3"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="documents-table__icon"
                  >
                    <path
                      d="M8 1C3.58862 1 0 4.58862 0 9C0 13.4114 3.58862 17 8 17C12.4114
                      17 16 13.4114 16 9C16 4.58862 12.4114 1 8 1ZM12.0547 7.30469L7.72131
                      11.6379C7.59131 11.7679 7.42065 11.8334 7.25 11.8334C7.07935 11.8334
                      6.90869 11.7679 6.77869 11.6379L4.61206 9.47131C4.35132 9.21069
                      4.35132 8.78931 4.61206 8.52869C4.87268 8.26794 5.29395 8.26794
                      5.55469 8.52869L7.25 10.224L11.1121 6.36206C11.3727 6.10132 11.7939
                      6.10132 12.0547 6.36206C12.3153 6.62268 12.3153 7.04395 12.0547 7.30469Z"
                      fill="#5DC397"
                    />
                  </svg>
                  <svg
                    v-if="document.status.id === 2"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="documents-table__icon"
                  >
                    <path
                      d="M8 1C3.58862 1 0 4.58862 0 9C0 13.4114 3.58862 17 8 17C12.4114
                      17 16 13.4114 16 9C16 4.58862 12.4114 1 8 1ZM12.0547 7.30469L7.72131
                      11.6379C7.59131 11.7679 7.42065 11.8334 7.25 11.8334C7.07935 11.8334
                      6.90869 11.7679 6.77869 11.6379L4.61206 9.47131C4.35132 9.21069
                      4.35132 8.78931 4.61206 8.52869C4.87268 8.26794 5.29395 8.26794
                      5.55469 8.52869L7.25 10.224L11.1121 6.36206C11.3727 6.10132 11.7939
                      6.10132 12.0547 6.36206C12.3153 6.62268 12.3153 7.04395 12.0547 7.30469Z"
                      fill="#C1C5CA"
                    />
                  </svg>
                  <div
                    class="documents-table__cell-text"
                    :class="{
                      'documents-table__cell-text--alert': document.status.id === 1,
                    }"
                  >
                    {{ locale === 'en' ? document.status.nameEn : document.status.name }}
                  </div>
                </div>
                <!--Three dots area-->
                <div class="documents-table__dot-button">
                  <popup-wrapper
                    arrow
                    placement="bottom-end"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="documents-table__status-more-icon"
                    >
                      <rect y="6" width="4" height="4" rx="2" fill="#424551"/>
                      <rect x="6" y="6" width="4" height="4" rx="2" fill="#424551"/>
                      <rect x="12" y="6" width="4" height="4" rx="2" fill="#424551"/>
                    </svg>
                    <template #popperContent>
                      <div
                        class="documents-table__popup-wrapper"
                      >
                        <a
                          v-if="document.status.id === 1 && isDiadoc"
                          class="documents-table__popup-text"
                          href="https://auth.kontur.ru/"
                          target="_blank"
                        >
                          {{ t('signInADiadoc') }}
                        </a>
                        <label
                          v-if="document.status.id === 1 && !isDiadoc"
                          class="documents-table__popup-text"
                        >
                          {{ t('uploadDoc') }}
                          <input
                            style="display: none;"
                            type="file"
                            :data-uploads-id="document.uploadsId"
                            @change="uploadDoc"
                          >
                        </label>
                        <div
                          class="documents-table__popup-text"
                          @click="downloadDoc(document.uploadsId)"
                        >
                          {{ t('downloadDoc') }}
                        </div>
                      </div>
                    </template>
                  </popup-wrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="documents-table__body"
        >
          <stub-block-no-data
            style="margin-top: 30px"
            :title="`Пока здесь нет документов`"
            :sub-title="`Позже вы сможете увидеть на этой странице договоры, акты и отчетность
            комплекса.`"
          >
            <template #icon>
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 20V3H0V21C0 21.2652 0.105357 21.5196 0.292893 21.7071C0.48043
                21.8946 0.734784 22 1 22H24V20H2Z"/>
                <path d="M9 12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11
                8 11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4
                12V19H9V12Z"/>
                <path d="M16 8C16 7.73478 15.8946 7.48043 15.7071 7.29289C15.5196 7.10536 15.2652
                7 157H12C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478
                11 8V19H16V8Z"/>
                <path d="M23 4C23 3.73478 22.8946 3.48043 22.7071 3.29289C22.5196 3.10536 22.2652 3
                22 3H19C18.7348 3 18.4804 3.10536 18.2929 3.29289C18.1054 3.48043 18 3.73478 18
                4V19H23V4Z"/>
              </svg>
            </template>
          </stub-block-no-data>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import UiLabel from '@/ui-kit/UiLabel/UiLabel.vue';
import SecondaryButton from '@/ui-kit/SecondaryButton/SecondaryButton.vue';
import FilterDropdown from '@/components/FilterDropdown/FilterDropdown.vue';
import StubBlockNoData from '@/components/StubBlockNoData/StubBlockNoData.vue';
import { useI18n } from 'vue-i18n';
import PopupWrapper from '../PopupWrapper/PopupWrapper.vue';
import translate from './translate';

const CATEGORY_COLORS = {
  34534: '#ECEEF1',
  2: '#FFF4DE',
  372390: '#E9F5F0',
  372391: '#D3E3FF',
  15: '#D3FFDD',
  372382: '#D3FFDD',
  630158: '#FFF4DE',
  372381: '#E9F5F0',
  1: '#D3E3FF',
};

export default {
  name: 'DocumentsTable',
  components: {
    UiLabel,
    SecondaryButton,
    FilterDropdown,
    PopupWrapper,
    StubBlockNoData,
  },
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    categoryList: {
      type: Array,
      default: () => [],
    },
    unitList: {
      type: Array,
      default: () => [],
    },
    locationList: {
      type: Array,
      default: () => [],
    },
    isStub: {
      type: Boolean,
      default: false,
    },
    isDiadoc: {
      type: Boolean,
      default: false,
    },
    isInvestor: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onFilterChange', 'signInDiadock', 'downloadDoc', 'downloadToSign', 'uploadDoc'],
  setup(props, { emit }) {
    const { t, mergeLocaleMessage, locale } = useI18n();
    mergeLocaleMessage('en', translate.en);
    mergeLocaleMessage('ru', translate.ru);
    const sortOrder = ref(null);
    const filteredList = computed(() => [...props.documents].sort((a, b) => {
      if (sortOrder.value === 'asc') {
        return Number(a.rawDate ? new Date(a.rawDate).getTime() : 0)
          - Number(b.rawDate ? new Date(b.rawDate).getTime() : 0);
      }
      return Number(b.rawDate ? new Date(b.rawDate).getTime() : 0)
        - Number(a.rawDate ? new Date(a.rawDate).getTime() : 0);
    }));

    const sortByDate = () => {
      if (sortOrder.value === null) {
        sortOrder.value = 'desc';
      } else {
        sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
      }
    };

    const categorySelectHandler = (e) => {
      emit('onFilterChange', { key: 'category', options: e });
    };

    const locationSelectHandler = (e) => {
      emit('onFilterChange', { key: 'location', options: e });
    };

    const unitSelectHandler = (e) => {
      emit('onFilterChange', { key: 'unit', options: e });
    };

    const signInDiadock = () => {
      emit('signInDiadock');
    };

    const downloadDoc = (id) => {
      emit('downloadDoc', id);
    };

    const downloadToSign = () => {
      emit('downloadToSign');
    };

    const uploadDoc = (e) => {
      emit('uploadDoc', {
        file: e.target.files[0],
        uploadsId: e.target.dataset.uploadsId,
      });
    };

    return {
      t,
      locale,
      CATEGORY_COLORS,
      sortOrder,
      filteredList,
      sortByDate,
      categorySelectHandler,
      locationSelectHandler,
      unitSelectHandler,
      signInDiadock,
      downloadDoc,
      downloadToSign,
      uploadDoc,
    };
  },
};
</script>

<style lang="scss">
.documents-table {
  min-width: 1216px;
  &__controls {
    display: none;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    color: $main-black;
    font-weight: $font-weight-regular;
    font-size: $font-size-regular;
  }

  &__header {
    font-size: $font-size-tiny;
    font-weight: $font-weight-half-bold;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    .secondary-button, .filter-button__label {
      height: 24px;
      color: $main-black;
      font-size: $font-size-tiny;
      font-weight: $font-weight-half-bold;
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }

    .filter-button__value {
      font-weight: $font-weight-medium;
    }
  }

  &__row {
    display: flex;
    width: 100%;
    background-color: #fff;
    justify-content: space-between;
  }

  &__cell {
    flex-grow: 1;
    text-align: left;

    &-content {
      display: flex;
      padding: 12px 18px 12px 12px;
    }

    &-text {
      margin-left: 4px;
      font-size: $font-size-regular;
      line-height: 16px;
      font-weight: $font-weight-regular;

      &--alert {
        color: $red500;
      }

      &--grey {
        color: $grey700;
      }
    }

    &-wrapper {
      display: flex;
      justify-content: flex-start;
      //align-items: flex-start;
      width: 132px;
      flex-shrink: 0;
    }

    &-label {
      margin-left: 6px;
    }

    &--date {
      max-width: 117px;
      min-width: 117px;
    }

    &--category {
      min-width: 186px;
      max-width: 186px;
    }

    &--location {
      min-width: 152px;
      max-width: 152px;
    }

    &--unit {
      min-width: 161px;
      max-width: 161px;
    }

    &--unit-num {
      min-width: 111px;
      max-width: 111px;
    }

    &--description {
      flex-grow: 2;
      max-width: 280px;
    }

    &--status {
      max-width: 215px;
      min-width: 215px;

      .documents-table__cell-content {
        justify-content: space-between;
      }
    }
  }

  &__tablet-content {
    display: none;
  }

  &__stub {
    .documents-table {
      &__header {
        color: $grey400;
      }

      &__body {
        padding-top: 85px;
      }
    }
  }

  &__icon {
    flex-shrink: 0;
  }

  &__documents {
    .documents-table {
      &__row {
        border-bottom: 1px solid $grey200;

        // &:first-child {
        //   border-top: 1px solid #eff2f6;
        // }
        &:nth-child(odd) {
          background-color: $grey100;
        }
      }

      &__header {
        .documents-table__row {
          background-color: #fff;
        }

        .documents-table__cell {
          display: flex;
          align-items: center;
          width: 100%;

          .documents-table__cell-content {
            width: 100%;
            padding-bottom: 6px;
          }
        }
      }
    }
  }

  &__sort-icon {
    &--desc {
      transform: rotate(180deg);
    }
  }

  &__dot-button {
    .popup-wrapper {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__popup {
    &-wrapper {
      padding: 16px 20px 20px 20px;
    }

    &-text {
      background: linear-gradient(180deg, #2B54E2 100%, #2B54E2 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: $font-size-regular;
      font-weight: $font-weight-medium;
      line-height: 18px;
      margin-top: 8px;

      &:first-child {
        margin-top: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .documents-table {
    &__cell {
      &-content {
        padding: 16px 12px 10px;
      }

      &--date {
        min-width: 77px;
        max-width: 77px;
      }

      &--category {
        min-width: 142px;
        max-width: 142px;
      }

      &-label {
        margin-left: 0;
      }

      &--location {
        min-width: 112px;
        max-width: 112px;
      }

      &--unit {
        min-width: 102px;
        max-width: 102px;
      }

      &--unit-num {
        min-width: 75px;
        max-width: 75px;
      }

      &--description {
        min-width: 163px;
        max-width: 163px;
      }

      &--status {
        min-width: 194px;
        max-width: 194px;
      }
    }

    &__icon {
      width: 16px;
      height: 16px;
    }

    &__controls {
      display: flex;
      width: 340px;
      height: 40px;
      gap: 8px;

      .secondary-button {
        font-size: $font-size-small;
        color: $grey900;
        font-weight: $font-weight-medium;
        line-height: 18px;
      }

      .filter-dropdown {
        .filter-button {
          height: 40px;

          &__label {
            font-size: $font-size-small;
            color: $grey900;
            font-weight: $font-weight-medium;
            line-height: 18px;
          }
        }
      }
    }

    &__desktop-button {
      display: none;
    }

    &__tablet-content {
      display: flex;
    }

    &__body {
      .documents-table__cell {
        &--date {
          .documents-table__cell-content {
            word-break: break-all;
            line-height: 17px;
            max-width: 65px;
          }
        }

        &--unit-num {
          .documents-table__cell-content {
            word-break: break-all;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .documents-table {
    &__controls {
      width: 430px;
      gap: 0 12px;

      .filter-button {
        &__label {
          color: $main-black;
          font-weight: $font-weight-medium;
        }
      }

      .secondary-button {
        text-transform: none;
      }
    }

    &__row {
      &--state-alert {
        background-color: $red100!important;
      }
    }
  }
}
</style>
