/* eslint-disable */
<template>
  <content-layout
    no-padding
    :locations-list="userLocations"
    :current-location="currentUserLocation"
    @location-change="locationChangeHandler"
  >
    <template #mobile-controls>
      <main-side-menu
        :item-list="menuItems"
      />
    </template>
    <template #main-content>
      <div class="promo__wrapper">
        <div class="promo__screen">
          <div class="promo__screen-inner">
            <div class="promo__screen-info">
              <h1 class="promo__screen-title">
                <span>{{t("promo")}}</span><span class="promo__screen-title--highlighted">inwis</span>
              </h1>
              <div class="promo__screen-text">
                {{t("promoThisPageYouCan")}}
              </div>
              <div class="promo__screen-controls">
                <primary-button
                  class="promo__screen-button"
                  green-light
                  :label="t('viewUnit')"
                  @click="scrollTo('units')"
                />
              </div>
            </div>
            <div class="promo__cards">
              <div class="promo__cards-left">
                <div class="promo__card">

                  <div class="promo__card-number">
                    <span>25</span><span class="promo__card-number-units">%</span>
                  </div>
                  <div class="promo__card-text">
                    {{t("annual")}} — <br>  {{t("average")}}  <br> {{t("profit")}}
<!--                    Годовая доходность<br> юнитов до-->
                  </div>
                </div>
              </div>
              <div class="promo__cards-right">
                <div class="promo__card promo__card--green">
                  <div class="promo__card-number">
                    <span>2,5</span><span class="promo__card-number-units">{{t("month")}}</span>
                  </div>
                  <div class="promo__card-text">
                    {{t("fromSigning")}}
                  </div>

                </div>
                <div class="promo__card promo__card--purple" style="margin-top: 20px;">
                  <div class="promo__card-number">
                    <span>8</span><span class="promo__card-number-units">%</span>
                  </div>
                  <div class="promo__card-text">
                    {{t("annual")}} — <br> {{t("guaranteed")}} <br>
                    {{t("minimumIncome")}}
                  </div>

                </div>
              </div>
            </div>
            <div class="promo__tablet-cards">
              <div class="promo__card promo__card--white">

                <div class="promo__card-number">
                  <span>25</span><span class="promo__card-number-units">%</span>
                </div>
                <div class="promo__card-text">
                  {{t("annualAverage")}}
                  <!--                  Годовая доходность<br> юнитов до-->
                </div>
              </div>
              <div class="promo__card promo__card--green">
                <div class="promo__card-number">
                  <span>2,5</span><span class="promo__card-number-units">{{t("month")}}</span>
                </div>
                <div class="promo__card-text">
                 {{t("signingContract")}}
                </div>

              </div>
              <div class="promo__card promo__card--purple">
                <div class="promo__card-number">
                  <span>8</span><span class="promo__card-number-units">%</span>
                </div>
                <div class="promo__card-text">
                  {{t("annualGaranted")}}
                </div>

              </div>
            </div>
          </div>
        </div>
        <div
          ref="units"
          class="promo__content"
        >
          <div class="promo__row">
            <h4 class="promo__content-title">{{t("lots")}}</h4>
            <div class="promo__filters">
              <filter-dropdown
                v-if="locationFilterOptions.length"
                filter-name="Локация"
                :filter-values="locationFilterOptions"
                :reset-filter="!isStateFilterSelected && !isTypeFilterSelected"
                @filter-select="locationSelectHandler"
              />
<!--              <filter-dropdown-->
<!--                v-if="typeFilterOptions.length"-->
<!--                filter-name="Тип актива"-->
<!--                :filter-values="typeFilterOptions"-->
<!--                :reset-filter="!isStateFilterSelected && !isLocationFilterSelected"-->
<!--                @filter-select="typeSelectHandler"-->
<!--              />-->
              <filter-dropdown
                v-if="stateFilterOptions.length"
                filter-name="Стадия"
                :reset-filter="!isLocationFilterSelected && !isTypeFilterSelected"
                :filter-values="stateFilterOptions"
                @filter-select="stateSelectHandler"
              />
            </div>
          </div>
          <div
            v-if="promoUnits.length"
            class="promo__unit-cards"
          >
            <promo-unit-card
              class="promo__unit-card"
              v-for="(unit, index) in filteredPromoUnits"
              :key="`${unit.unitName}_${unit.investorPrice}_${index}`"
              v-bind="unit"
              @on-click="scrollTo('requestCallback')"
            />
          </div>
        </div>
<!--        <div-->
<!--          ref="requestCallback"-->
<!--          class="promo__call-block"-->
<!--        >-->
<!--          <div class="promo__call-block-inner">-->
<!--            <h3 class="promo__call-block-title">Забронировать юнит</h3>-->
<!--            <div class="promo__call-block-text">-->
<!--              Менеджер отдела инвестиций ответит на ваши вопросы,-->
<!--              защитит финансовую модель и запишет на просмотр локации.-->
<!--            </div>-->
<!--            <div class="promo__call-block-form">-->
<!--              <div-->
<!--                v-if="!isPromoCallbackRequested"-->
<!--                class="promo__call-block-form-field"-->
<!--              >-->
<!--                <label for="call-block-tel" class="promo__call-block-form-label">Телефон</label>-->
<!--                <input-->
<!--                  id="call-block-tel"-->
<!--                  type="tel"-->
<!--                  class="promo__call-block-form-input"-->
<!--                  v-maska="MASKS.tel"-->
<!--                  :placeholder="MASKS.tel"-->
<!--                  v-model="callbackTel"-->
<!--                >-->
<!--              </div>-->
<!--              <div-->
<!--                v-else-->
<!--                class="promo__call-block-form-success"-->
<!--              >-->
<!--                <svg width="13" height="12" viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M6.5 0C3.19147 0 0.5 2.69147 0.5 6C0.5 9.30853 3.19147 12 6.5 12C9.80853-->
<!--                12 12.5 9.30853 12.5 6C12.5 2.69147 9.80853 0 6.5 0ZM9.54102 4.72852L6.29099-->
<!--                7.97845C6.19348 8.07596 6.06549 8.12503 5.9375 8.12503C5.80951 8.12503 5.68152-->
<!--                8.07596 5.58401 7.97845L3.95905 6.35349C3.76349 6.15802 3.76349 5.84198 3.95905-->
<!--                5.64651C4.15451 5.45096 4.47046 5.45096 4.66602 5.64651L5.9375 6.918L8.83405-->
<!--                4.02155C9.02951 3.82599 9.34546 3.82599 9.54102 4.02155C9.73648 4.21701 9.73648-->
<!--                4.53296 9.54102 4.72852Z"/>-->
<!--                </svg>-->
<!--                <span>Спасибо! Заявка отправлена</span>-->
<!--              </div>-->
<!--              <primary-button-->
<!--                v-if="!isPromoCallbackRequested"-->
<!--                class="promo__call-block-form-button"-->
<!--                label="Отправить"-->
<!--                green-light-->
<!--                @click="sendRequestToCallback"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="promo__content">-->
<!--          <h4 class="promo__content-title">Расчет доходности</h4>-->
<!--          <div class="promo__content-text hide-on-mobile">-->
<!--            На примере юнита категории «Полулюкс» (Junior Suite) в локации Волочаевка-->
<!--          </div>-->
<!--          <div class="promo__cards-row">-->
<!--            <div class="promo__card promo__card&#45;&#45;light-green">-->
<!--              <div class="promo__card-text">-->
<!--                Цена на старте продаж-->
<!--              </div>-->
<!--              <div class="promo__card-number">-->
<!--                <span>5,1</span><span class="promo__card-number-units">млн</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="promo__card promo__card&#45;&#45;green">-->
<!--              <div class="promo__card-text">-->
<!--                Доход с юнита в месяц до-->
<!--              </div>-->
<!--              <div class="promo__card-number">-->
<!--                <span>186</span><span class="promo__card-number-units">тыс</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="promo__card promo__card&#45;&#45;purple">-->
<!--              <div class="promo__card-text">-->
<!--                Доход с юнита в год до-->
<!--              </div>-->
<!--              <div class="promo__card-number">-->
<!--                <span>2,2</span>-->
<!--                <span class="promo__card-number-units">млн</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="promo__content">-->
<!--          <h4 class="promo__content-title">Расчет окупаемости</h4>-->
<!--          <div class="promo__content-text hide-on-mobile">-->
<!--            На примере юнита категории «Полулюкс» (Junior Suite) в локации Волочаевка-->
<!--          </div>-->
<!--          <img-->
<!--            src="../assets/promo-graph.svg"-->
<!--            alt="Пример окупаемость"-->
<!--            class="promo__content-img hide-on-mobile"-->
<!--          >-->
<!--          <div class="promo__mobile-cards">-->
<!--            <div class="promo__card promo__card&#45;&#45;light-green">-->
<!--              <div class="promo__card-text">-->
<!--                Цена на старте продаж-->
<!--              </div>-->
<!--              <div class="promo__card-number">-->
<!--                <span>5,1</span><span class="promo__card-number-units">млн</span>-->
<!--              </div>-->
<!--              <div class="promo__card-label">-->
<!--                апр 2023-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="promo__card promo__card&#45;&#45;green">-->
<!--              <div class="promo__card-text">-->
<!--                Окупаемость до-->
<!--              </div>-->
<!--              <div class="promo__card-number">-->
<!--                <span>6,1</span><span class="promo__card-number-units">млн</span>-->
<!--              </div>-->
<!--              <div class="promo__card-label">-->
<!--                апр 2027-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="promo__card promo__card&#45;&#45;green">-->
<!--              <div class="promo__card-text">-->
<!--                Окупаемость до-->
<!--              </div>-->
<!--              <div class="promo__card-number">-->
<!--                <span>12,1</span><span class="promo__card-number-units">млн</span>-->
<!--              </div>-->
<!--              <div class="promo__card-label">-->
<!--                апр 2032-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="promo__discounts">-->
<!--          <div class="promo__discounts-inner">-->
<!--            <div class="promo__card promo__card&#45;&#45;purple">-->
<!--              <div class="promo__card-text">-->
<!--                Скидка на<br>все объекты-->
<!--              </div>-->
<!--              <div class="promo__card-number">-->
<!--                <span>2</span><span class="promo__card-number-units">%</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="promo__discounts-info">-->
<!--              <h3 class="promo__discounts-info-title">Скидки и бонусы для инвесторов</h3>-->
<!--              <div class="promo__discounts-info-text">-->
<!--                Для всех текущих инвесторов проекта VIEW GA действует скидка 2% на инвестирование в-->
<!--                юниты. А также вы получите 2% в качестве агентского вознаграждения, если другой-->
<!--                инвестор войдет в проект по вашей рекомендации. Подробные условия можно узнать у-->
<!--                вашего менеджера.-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="promo__card promo__card&#45;&#45;purple" style="margin-top: 88px;">-->
<!--              <div class="promo__card-text">-->
<!--                Агентский<br>бонус-->
<!--              </div>-->
<!--              <div class="promo__card-number">-->
<!--                <span>2</span><span class="promo__card-number-units">%</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="promo__tablet-discounts">-->
<!--          <div class="promo__discounts-inner">-->
<!--            <div class="promo__discounts-info">-->
<!--              <h3 class="promo__discounts-info-title">Скидки и бонусы для инвесторов</h3>-->
<!--              <div class="promo__discounts-info-text">-->
<!--                Для всех текущих инвесторов проекта VIEW GA действует скидка 2% на инвестирование в-->
<!--                юниты. А также вы получите 2% в качестве агентского вознаграждения, если другой-->
<!--                инвестор войдет в проект по вашей рекомендации. Подробные условия можно узнать у-->
<!--                вашего менеджера.-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="promo__tablet-inner-cards">-->
<!--              <div class="promo__card promo__card&#45;&#45;purple">-->
<!--                <div class="promo__card-text">-->
<!--                  Скидка на<br>все объекты-->
<!--                </div>-->
<!--                <div class="promo__card-number">-->
<!--                  <span>2</span><span class="promo__card-number-units">%</span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="promo__card promo__card&#45;&#45;purple">-->
<!--                <div class="promo__card-text">-->
<!--                  Агентский<br>бонус-->
<!--                </div>-->
<!--                <div class="promo__card-number">-->
<!--                  <span>2</span><span class="promo__card-number-units">%</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </template>

  </content-layout>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { maska } from 'maska';
import ContentLayout from '@/layouts/ContentLayout.vue';
import PrimaryButton from '@/ui-kit/PrimaryButton/PrimaryButton.vue';
import PromoUnitCard from '@/components/PromoUnitCard/PromoUnitCard.vue';
import MainSideMenu from '@/components/MainSideMenu/MainSideMenu.vue';
import FilterDropdown from '@/components/FilterDropdown/FilterDropdown.vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const MASKS = {
  tel: '+7 (###) ###-##-##',
};

export default {
  name: 'PromoPage',
  directives: {
    maska,
  },
  components: {
    ContentLayout,
    PrimaryButton,
    PromoUnitCard,
    MainSideMenu,
    FilterDropdown,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const userState = store.state.user;
    const promoState = store.state.promo;
    const menuItems = computed(() => userState.sideMenuItems);
    const currentUserLocation = computed(() => userState.currentLocation);
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name, labelEn: location.nameEn })));
    const baseSettings = computed(() => store.getters['user/baseProfileSettings']);

    const promoUnits = computed(() => promoState.units);
    const isPromoCallbackRequested = computed(() => promoState.isPromoCallbackRequested);

    const callbackTel = ref('');
    const units = ref(null);
    const requestCallback = ref(null);
    const anchors = {
      units,
      requestCallback,
    };
    const locationFilterOptions = ref([]);

    const typeFilterOptions = ref([]);
    const stateFilterOptions = ref([]);

    const currentFilter = ref(null);
    const isLocationFilterSelected = ref(false);
    const isTypeFilterSelected = ref(false);
    const isStateFilterSelected = ref(false);
    const filteredPromoUnits = ref(promoUnits.value);

    const initPromoPage = async () => {
      store.dispatch('base/setCurrentPath', useRoute());
      await store.dispatch('user/getUserProfileSettings');
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/initSideMenuItems');
      await store.dispatch('promo/getPromoUnits');

      promoUnits.value.forEach((unit, index) => {
        locationFilterOptions.value = locationFilterOptions.value
          .findIndex((item) => item.label === unit.labelsObject.location.text) > -1
          ? locationFilterOptions.value
          : [...locationFilterOptions.value, { id: index + 1, label: unit.labelsObject.location.text }];

        typeFilterOptions.value = typeFilterOptions.value
          .findIndex((item) => item.label === unit.labelsObject.unitType.text) > -1
          ? typeFilterOptions.value
          : [...typeFilterOptions.value, { id: index, label: unit.labelsObject.unitType.text }];

        stateFilterOptions.value = stateFilterOptions.value
          .findIndex((item) => item.label === unit.labelsObject.unitState.text) > -1
          ? stateFilterOptions.value
          : [...stateFilterOptions.value, { id: index + 1, label: unit.labelsObject.unitState.text }];
      });

      filteredPromoUnits.value = promoUnits.value;
    };

    const selectFilter = (filterType, text) => {
      isStateFilterSelected.value = filterType === 'unitState';
      isTypeFilterSelected.value = filterType === 'unitType';
      isLocationFilterSelected.value = filterType === 'location';

      if (!text) {
        filteredPromoUnits.value = promoUnits.value;
        currentFilter.value = null;
        return;
      }

      currentFilter.value = {
        filterType,
        text,
      };

      filteredPromoUnits.value = promoUnits.value
        .filter((unit) => unit.labelsObject[currentFilter.value.filterType].text === text);
    };

    const locationSelectHandler = (selectedValue) => {
      selectFilter('location', selectedValue.label);
    };
    const typeSelectHandler = (selectedValue) => {
      selectFilter('unitType', selectedValue.label);
    };
    const stateSelectHandler = (selectedValue) => {
      selectFilter('unitState', selectedValue.label);
    };

    const scrollTo = (anchor) => {
      anchors[anchor].value.scrollIntoView({ behavior: 'smooth' });
    };

    const sendRequestToCallback = () => {
      if (callbackTel.value.length) {
        store.dispatch('promo/requestPromoCallback', callbackTel.value);
      }
    };

    watch(baseSettings, (settings) => {
      let i = 0;
      callbackTel.value = MASKS.tel.replace(/#/g, () => settings.phone.value.toString()[i++]);
    });

    const locationChangeHandler = (id) => {
      store.dispatch('user/setCurrentLocation', id);
      initPromoPage();
    };

    initPromoPage();
    return {
      t,
      userLocations,
      currentUserLocation,
      menuItems,
      promoUnits,
      isPromoCallbackRequested,
      units,
      requestCallback,
      callbackTel,
      MASKS,
      locationFilterOptions,
      typeFilterOptions,
      stateFilterOptions,
      isLocationFilterSelected,
      isTypeFilterSelected,
      isStateFilterSelected,
      filteredPromoUnits,
      locationChangeHandler,
      scrollTo,
      sendRequestToCallback,
      locationSelectHandler,
      typeSelectHandler,
      stateSelectHandler,
    };
  },
};
</script>

<style lang="scss" scoped>

.primary-button{
  background: #022082;
}
.promo {
  &__mobile-cards {
    display: none;
  }

  &__wrapper {
    width: 100%;
  }

  &__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__filters {
    display: flex;

    .filter-dropdown {
      margin-left: 8px;
    }
  }

  &__content {
    max-width: 1920px;
    padding: 140px 100px 0;
    margin: 0 auto;

    &:last-child {
      padding-bottom: 50px;
    }

    &-title {
      margin: 0;
      line-height: 40px;
      color: $main-black;
      font-size: $font-size-title-large;
      font-weight: $font-weight-bold;
    }

    &-text {
      margin-top: 12px;
      color: $main-black;
      font-size: $font-size-medium;
      font-weight: $font-weight-regular;
    }

    &-img {
      margin-top: 32px;
      width: 100%;
    }
  }

  &__cards-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;

    .promo__card {
      width: 33.33%;
      margin-right: 24px;
      flex-grow: 1;

      &:last-child {
        margin-right: 0;
      }

      &-number {
        margin-top: 60px;
      }
    }
  }

  &__screen {
    width: 100%;
    padding: 86px 0;
    margin-bottom: -40px;
    background-image: url('@/assets/promo-call_bg-new.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;

    &-inner {
      display: flex;
      justify-content: space-between;
      max-width: 1920px;
      margin: 0 auto;
    }

    &-info {
      width: 375px;
      margin-left: 96px;
    }

    &-title {
      margin: 0;
      line-height: 60px;
      color: #093DC7;
      font-weight: $font-weight-bold;
      font-size: 52px;

      &--highlighted {
        color: #6FA9FF;
      }

      span {
        display: block;
      }
    }

    &-text {
      margin-top: 12px;
      line-height: 22px;
      color: $blue800;
      font-weight: $font-weight-regular;
      font-size: $font-size-medium;
    }

    &-controls {
      margin-top: 42px;
      max-width: 189px;

      .primary-button {
        max-width: 100%;
      }
    }

    &-button {
      text-decoration: none;
      color: #fff;
    }
  }

  &__cards {
    display: flex;
    justify-content: space-between;
    width: 495px;
    margin-right: 100px;

    &-left {
      margin-top: 132px;
      margin-right: 20px;
    }

    &-right {
      max-width: 293px;
    }
  }

  &__tablet-cards {
    display: none;
  }

  &__card {
    padding: 10px 14px 25px 16px;
    background: $grey100;
    border: 1px solid $grey300;
    box-shadow: 0 7px 13px rgba(172, 176, 194, 0.37);
    border-radius: 12px;

    &-number {
      display: flex;
      align-items: start;
      margin-top: 10px;
      color: $blue800;
      line-height: 1;
      font-weight: $font-weight-medium;
      font-size: 100px;

      &-units {
        font-weight: $font-weight-bold;
        font-size: 32px;
      }
    }

    &-text {
      color: $blue800;
      font-weight: $font-weight-regular;
      font-size: $font-size-small
    }

    &--green {
      background-color: $blue800;

      .promo__card {
        &-number {
          color: #6FA9FF;
        }

        &-text {
          color: #fff;
          margin-top: 15px;
        }
      }
    }

    &--light-green {
      background-color: #fff;

      .promo__card {
        &-number {
          color: $blue400;
        }

        &-text {
          color: $main-black;
        }
      }
    }

    &--purple {
      width: 207px;
      background-color: #ECF4FF;
      box-shadow: 0 7px 13px 0 #ACB0C25E;

      .promo__card {
        &-number {
          color: #093DC7;
        }

        &-text {
          color: #093DC7;
        }
      }
    }
  }

  &__unit-cards {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px 24px;
  }

  &__call-block {
    width: 100%;
    margin-top: 140px;
    padding: 64px 0 80px;
    overflow: hidden;
    border-radius: 12px;
    background-color: $green500;
    background-image: url('@/assets/promo-mask-mobile.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;

    &-inner {
      max-width: 1920px;
      padding: 0 100px;
      margin: 0 auto;
    }

    &-title {
      margin: 0;
      line-height: 48px;
      color: #fff;
      font-weight: $font-weight-bold;
      font-size: 40px;
    }

    &-text {
      max-width: 660px;
      margin-top: 12px;
      color: #fff;
      font-weight: $font-weight-regular;
      font-size: $font-size-medium;
    }

    &-form {
      display: flex;
      align-items: flex-end;
      max-width: 510px;

      &-field {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        margin-top: 32px;
        margin-right: 16px;
      }

      &-label {
        margin-bottom: 8px;
        line-height: 22px;
        color: rgba(255, 255, 255, 0.4);
        font-weight: $font-weight-medium;
        font-size: $font-size-medium;
      }

      &-input {
        height: 42px;
        padding: 10px;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid $grey300;
        border-radius: 4px;
        color: $grey900;
      }

      &-button {
        width: 174px;
        box-sizing: border-box;
      }

      &-success {
        margin-top: 62px;
        height: 42px;
        line-height: 42px;
        fill: $blue400;
        color: #fff;
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;

        svg {
          margin-right: 8px;
        }
      }
    }
  }

  &__discounts {
    padding: 55px 0 70px;
    margin-top: 140px;
    margin-bottom: -32px;
    overflow: hidden;
    border-radius: 12px;
    background-color: $green500;
    background-image: url('@/assets/promo-mask-mobile.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;

    .promo__card {
      width: 157px;
      border-color: $blue400;
      box-shadow: none;
    }

    &-inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 1920px;
      padding: 0 100px;
      margin: 0 auto;
    }

    &-info {
      max-width: 555px;
      text-align: center;
      color: #fff;

      &-title {
        margin: 32px 0 0;
        line-height: 48px;
        font-size: 40px;
        font-weight: $font-weight-bold;
      }

      &-text {
        margin-top: 16px;
        line-height: 22px;
        font-size: $font-size-medium;
      }
    }
  }

  &__tablet-discounts {
    display: none;
  }
}

@media screen and (min-width: 1601px) {
  .promo {
    &__screen {
      padding: 86px 0 182px;
      margin-bottom: -100px;
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .promo {
    &__wrapper {
      overflow: hidden;
    }
  }
}

@media screen and (max-width: 475px) {
  .promo {
    &__unit-cards {
      grid-template-columns: 1fr;
      gap: 65px 0;
    }
  }
}

@media screen and (min-width: 1921px) {
  .promo {
    &__screen {
      padding: 186px 0 282px;
      margin-bottom: -180px;
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .tablet-spacing {
    letter-spacing: -5px;
  }

  .promo {
    &__screen {
      background-position: center;
      padding: 0;
      margin: 76px 0 0 0;

      &-button {
        font-size: $font-size-regular;
        line-height: 18px;
        width: 167px;
      }

      &-inner {
        flex-direction: column;
        align-items: center;
      }

      &-info {
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-title {
        display: flex;

        &--highlighted {
          margin-left: 15px;
        }
      }

      &-text {
        width: 493px;
        text-align: center;
      }
    }

    &__cards {
      display: none;
    }

    &__tablet-cards {
      margin-top: 92px;
      width: 100%;
      display: flex;
      padding: 0 24px;
    }

    &__card {
      width: 33.33%;
      margin-right: 24px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 168px;

      &:last-child {
        margin-right: 0;
      }

      &-number {
        margin: 0;
        line-height: 80px;
        font-size: 90px;

        &-units {
          line-height: 24px;
          font-size: $font-size-title-small;
        }
      }
    }

    &__content {
      margin-top: 67px;
      padding: 0 24px;

      &-title {
        line-height: 28px;
        font-size: $font-size-title;
      }
    }

    &__unit-cards {
      gap: 40px 23px;
    }

    &__call-block {
      margin-top: 50px;
      padding: 57px 25px 64px;

      &-inner {
        padding: 0;
      }

      &-title {
        line-height: 32px;
        font-size: $font-size-title-mid-large;
      }

      &-text {
        margin-top: 16px;
        max-width: 605px;
        line-height: 18px;
        font-size: $font-size-regular;
      }

      &-form {
        max-width: 100%;

        &-button {
          width: auto;
          flex-grow: 3;
          line-height: 18px;
          font-size: $font-size-regular;
        }
      }
    }

    &__cards-row {
      .promo__card {
        &-number {
          margin-top: 0;
        }
      }
    }

    &__discounts {
      display: none;
    }

    &__tablet-discounts {
      display: block;
      margin-top: 50px;
      overflow: hidden;
      border-radius: 12px;
      background-color: $green500;
      background-image: url('@/assets/promo-mask-mobile.png');
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: cover;

      .promo__discounts-inner {
        display: flex;
        padding: 0 35px 0 15px;
        margin: 49px 0 50px 0;
      }

      .promo__discounts-info {
        width: 50%;
        display: flex;
        flex-direction: column;

        &-title {
          margin-top: 9px;
          line-height: 32px;
          font-size: $font-size-title-mid-large;
          max-width: 215px;
          text-align: left;
        }

        &-text {
          line-height: 18px;
          font-size: $font-size-regular;
          margin-top: 16px;
          max-width: 355px;
          text-align: left;
        }
      }
    }

    &__tablet-inner-cards {
      display: flex;
      width: 50%;
      padding-left: 95px;

      .promo__card {
        &:nth-child(1) {
          margin-top: 26px;
        }

        &-number {
          font-size: 100px;

          &-units {
            font-size: $font-size-title-large;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .tablet-spacing {
    letter-spacing: -10px;
  }

  .hide-on-mobile {
    display: none;
  }
  .promo {
    &__wrapper {
      overflow: visible;
    }

    &__screen {
      width: calc(100% + 48px);
      margin: 45px 0 0 -24px;
      background-image:url('@/assets/promo-mask-mobile.png');
      background-position: top 80px center;

      &-inner {
        padding: 0 24px;
      }

      &-info {
        align-items: flex-start;
      }

      &-title {
        flex-direction: column;
        line-height: 42px;
        font-size: 36px;

        &--highlighted {
          margin: 0;
        }
      }

      &-text {
        max-width: 100%;
        text-align: left;
        line-height: 18px;
        font-size: $font-size-regular;
        margin: 8px 0 0 0;
      }

      &-controls {
        margin-top: 24px;
        max-width: 100%;
        width: 100%;
      }

      &-button {
        line-height: 22px;
        font-size: $font-size-medium;
        width: 100%;
      }
    }

    &__card {
      &-number {
        &-units {
          margin-left: 6px;
        }
      }
    }

    &__tablet-cards {
      padding: 0;
      margin-top: 60px;
      flex-direction: column;

      .promo__card {
        width: 100%;
        margin-top: 12px;

        &:nth-child(1) {
          margin-top: 0;
        }

        &-number {

          &-units {
            font-size: $font-size-title-large;
          }
        }
      }
    }

    &__content {
      margin-top: 46px;
      padding: 0;
    }

    &__unit-cards {
      display: flex;
      flex-direction: column;
      gap: 0;
    }

    &__unit-card {
      width: 100%;
      margin-top: 32px;
      padding-bottom: 32px;
      border-bottom: 1px solid $grey200;

      &:nth-child(1) {
        margin-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    &__call-block {
      width: calc(100% + 48px);
      margin-left: -24px;
      border-radius: 0;

      &-title {
        line-height: 24px;
        font-size: $font-size-title;
      }

      &-form {
        flex-direction: column;
        align-items: center;

        &-label {
          line-height: 18px;
          font-size: $font-size-regular;
        }

        &-field {
          margin: 32px 0 0 0;
          width: 100%;
        }

        &-button {
          width: 100%;
          margin: 16px 0 0 0;
        }
      }
    }

    &__cards-row {
      flex-direction: column;
      margin-top: 20px;

      .promo__card {
        width: 100%;
        margin: 12px 0 0 0;

        &:nth-child(1) {
          margin: 0;
        }

        &-number {
          font-size: 100px;
          line-height: 90px;

          &-units {
            font-size: 32px;
          }
        }
      }
    }

    &__mobile-cards {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .promo__card {
        width: 100%;
        margin: 12px 0 0 0;
        padding: 10px 17px 20px 17px;

        &:nth-child(1) {
          margin: 0;
        }

        &-number {
          color: $blue800;
          margin-top: 10px;
          font-size: 100px;
          line-height: 90px;

          &-units {
            font-size: $font-size-title-large;
          }
        }
      }

      .promo__card--green {
        .promo__card-number {
          color: $white;
        }
      }
    }

    &__card-label {
      font-size: $font-size-small;
      line-height: 16px;
      color: $blue400;
      font-weight: $font-weight-bold;
      margin-top: 30px;
    }

    &__tablet-discounts {
      width: calc(100% + 48px);
      margin-left: -24px;
      border-radius: 0;

      .promo {
        &__discounts-inner {
          flex-direction: column;
        }

        &__discounts-info {
          width: 100%;

          &-title {
            line-height: 30px;
            font-size: $font-size-title;
          }
        }

        &__tablet-inner-cards {
          width: 100%;
          padding: 0;
          margin-top: 24px;

          .promo__card {
            box-shadow: none;
          }
        }
      }
    }

    &__card--white {
      .promo__card-number {

        &-units {
          line-height: 36px;
        }
      }
    }
  }
}
</style>
