<template>
  <dashboard-layout
    class="control-page"
    :locations-list="userLocations"
    :current-location="currentUserLocation"
    @location-change="locationChangeHandler"
  >
    <template #outer-content>
      <ui-tabs
        :list="userLocations"
        :preselected-tab-id="currentUserLocation"
        @tab-change="locationChangeHandler"
      />
    </template>
    <template #side-content>
      <main-side-menu
        :item-list="menuItems"
        active-item-id="control"
      />
      <user-actives
        :units-list="userUnits"
      />
      <user-manager
        :manager-data="userManagerData"
      />
    </template>
    <template #main-content>
      <div class="control-page">
        <div class="control-page__title-block">
          <title-main
            class="control-page__title"
            :text="t('menuControls')"
            icon
          >
            <template #icon>
              <img src="../assets/title-ok-icon.svg" alt="booking-icon">
            </template>
          </title-main>
          <div class="control-page__info">
            {{t('onThisPage')}}
          </div>
        </div>
        <div class="control-page__content">
          <summary-table>
            <div class="summary-table__row">
              <div
                class="summary-table__block"
                v-if="lastInspectionDate"
              >
                <summary-info
                  :header="t('dateLastCheck')"
                  :firstInfo="lastInspectionDate"
                />
              </div>
              <div
                class="summary-table__block"
                v-if="averageResult"
              >
                <summary-info
                  :header="t('averageResult')"
                  :firstInfo="averageResult"
                />
              </div>
              <div
                class="summary-table__block"
                v-if="finishedInspectionCount"
              >
                <summary-info
                  :header="t('inspections')"
                  :firstInfo="finishedInspectionCount"
                />
              </div>
            </div>
          </summary-table>
          <div class="control-page__block">
            <title-secondary
              :text="t('resultInspections')"
            />
            <chart-legend
              :items="chartLegend"
            />
            <div class="control-page__chart-legend-text">
              {{t('percentagesShow')}}
            </div>
            <info-chart
              v-if="chartData.data && chartData.data.length"
              v-bind="chartData"
            />
          </div>
          <div class="control-page__block">
            <title-secondary
              :text="(t('allCheck'))"
            />
            <ui-tabs
              flat
              :list="inspectionsTabsLabels"
              :preselected-tab-id="inspectionsTabsLabels[0].id"
            >
              <template #buttons>
                <div class="control-page__buttons-wrapper">
                  <filter-dropdown
                    :filter-name="t('typeCheck')"
                    :filter-values="inspectionTypes"
                    @filter-select="inspectionTypeSelectHandler"
                  />
                  <filter-date
                    v-if="preSelectedDatesForFilter && preSelectedDatesForFilter.length"
                    calendar-only
                    is-range
                    is-month-picker
                    :preselected-range="preSelectedDatesForFilter"
                    @filter-date-select="filterByDate"
                  />
                </div>
              </template>
              <template #passed>
                <div class="control-page__check-cards">
                  <stub-block-no-data
                    v-if="!completedInspections.length"
                    :title="t('noChecksYet')"

                  >
                    <template #icon>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none">
                        <path fill="#89909E" d="M21.5 6.5V4.25A2.25 2.25 0 0 0
                        19.25 2h-3v-.75a.75.75
                      0 1 0-1.5 0V2h-7.5v-.75a.75.75 0 0 0-1.5 0V2h-3A2.25 2.25 0 0 0 .5
                      4.25V6.5h21ZM.5 8v11.25a2.25 2.25 0 0 0 2.25 2.25h16.5a2.25 2.25 0 0 0
                      2.25-2.25V8H.5Zm14.738 4.32-5.25 4.5a.75.75 0 0 1-1.018-.04l-2.25-2.25a.75.75
                      0 0 1 1.06-1.061l1.76 1.76 4.725-4.05a.748.748 0 0 1 1.216.329.75.75
                      0 0 1-.241.81l-.002.001Z"/>
                      </svg>
                    </template>
                  </stub-block-no-data>
                  <div
                    v-else
                    class="control-page__check-cards-item"
                    v-for="item in completedInspections"
                    :key="item.id"
                  >
                    <check-card
                      :date="item.inspectionDate"
                      :title="item.inspectionLabel"
                      withRoute
                      :pathName="`/dashboard/control/inspection/${item.id}`"
                      :result="item.result"
                      :resultPercent="item.percent"
                      :violation="item.statFails"
                      :type="item.inspectionType"
                    />
                  </div>
                </div>
              </template>
              <template #present>
                <div class="control-page__check-cards">
                  <stub-block-no-data
                    v-if="!upcomingInspections.length"
                    :title="t('noChecksYet')"
                    :sub-title="t('complexDidntHave')"
                  >
                    <template #icon>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none">
                        <path fill="#89909E" d="M21.5 6.5V4.25A2.25 2.25 0 0 0 19.25
                        2h-3v-.75a.75.75
                      0 1 0-1.5 0V2h-7.5v-.75a.75.75 0 0 0-1.5 0V2h-3A2.25 2.25 0 0 0 .5
                      4.25V6.5h21ZM.5 8v11.25a2.25 2.25 0 0 0 2.25 2.25h16.5a2.25 2.25 0 0 0
                      2.25-2.25V8H.5Zm14.738 4.32-5.25 4.5a.75.75 0 0 1-1.018-.04l-2.25-2.25a.75.75
                      0 0 1 1.06-1.061l1.76 1.76 4.725-4.05a.748.748 0 0 1 1.216.329.75.75
                      0 0 1-.241.81l-.002.001Z"/>
                      </svg>
                    </template>
                  </stub-block-no-data>
                  <div
                    v-else
                    class="control-page__check-cards-item"
                    v-for="item in upcomingInspections"
                    :key="item.id"
                  >
                    <check-card
                      :date="item.inspectionDate"
                      :title="item.inspectionLabel"
                      :type="item.inspectionType"
                    />
                  </div>
                </div>
              </template>
            </ui-tabs>
          </div>
        </div>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
import TitleSecondary from '@/ui-kit/TitleSecondary/TitleSecondary.vue';
import UiTabs from '@/ui-kit/UiTabs/UiTabs.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import MainSideMenu from '@/components/MainSideMenu/MainSideMenu.vue';
import UserActives from '@/components/UserActives/UserActives.vue';
import SummaryTable from '@/components/SummaryTable/SummaryTable.vue';
import SummaryInfo from '@/components/SummaryInfo/SummaryInfo.vue';
import ChartLegend from '@/ui-kit/ChartLegend/ChartLegend.vue';
import InfoChart from '@/components/infoChart/InfoChart.vue';
import FilterDropdown from '@/components/FilterDropdown/FilterDropdown.vue';
import CheckCard from '@/components/CheckCard/CheckCard.vue';
import StubBlockNoData from '@/components/StubBlockNoData/StubBlockNoData.vue';
import FilterDate from '@/components/FilterDate/FilterDate.vue';
import UserManager from '@/components/UserManager/UserManager.vue';
import { INSPECTIONS_TABS_LABELS, INSPECTIONS_TYPES } from '@/constants/control';
import { useI18n } from 'vue-i18n';

const CHART_DATA = {
  type: 'bar',
  yaxisOptions: {
    labels: {
      formatter(value) {
        return `${Math.floor(value)} %`;
      },
    },
    max: 100,
    tickAmount: 3,
  },
  customTooltip: {
    custom({ dataPointIndex, seriesIndex, w }) {
      const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

      return `<div class="info-chart__tooltip">
        <span class="info-chart__tooltip-label">Выявлено</span>
        <span class="info-chart__tooltip-sum">${data.fails} нарушений</span>
        <div class="info-chart__tooltip-underline"></div>
        <span class="info-chart__tooltip-label">Успех проверки</span>
        <span class="info-chart__tooltip-sum">${data.amount} %</span>
      </div>`;
    },
  },
  fillSeriesColor: true,
};

export default {
  name: 'controlPage',
  components: {
    TitleMain,
    TitleSecondary,
    MainSideMenu,
    DashboardLayout,
    UiTabs,
    UserActives,
    SummaryTable,
    SummaryInfo,
    ChartLegend,
    StubBlockNoData,
    InfoChart,
    FilterDropdown,
    CheckCard,
    FilterDate,
    UserManager,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const userState = store.state.user;
    const userManagerData = computed(() => userState.managerData);
    const controlState = store.state.control;
    const menuItems = computed(() => userState.sideMenuItems);
    const inspectionsTabsLabels = ref(INSPECTIONS_TABS_LABELS).value.map((item) => (
      {
        ...item,
        label: t(item.label),
      }
    ));
    console.log(inspectionsTabsLabels, 'inspectionsTabsLabels');
    const inspectionTypes = Object.keys(INSPECTIONS_TYPES).map((id) => ({
      id,
      label: INSPECTIONS_TYPES[id].label,
    }));
    const selectedInspectionType = ref({});
    const userUnits = computed(() => userState.units);
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name, labelEn: location.nameEn })));
    const currentUserLocation = computed(() => userState.currentLocation);
    const averageResult = computed(() => `${controlState.averageResult}\u202F%`);
    const completedInspections = computed(() => controlState
      .filteredCompletedInspections.filter((item) => controlState.currentFilterId
        ? item.isCompleted && item.patternColor === controlState.currentFilterId
        : item.isCompleted));
    const upcomingInspections = computed(() => controlState
      .filteredUpcomingInspections.filter((item) => controlState.currentFilterId
        ? !item.isCompleted && item.patternColor === controlState.currentFilterId
        : !item.isCompleted));
    const lastInspectionDate = computed(() => controlState.lastInspectionDate);
    const finishedInspectionCount = computed(() => controlState.finishedInspectionCount);
    const chartBarColors = computed(() => controlState.chartBarColors);
    const chartData = computed(() => ({
      ...CHART_DATA,
      strokeColors: chartBarColors.value,
      colors: chartBarColors.value,
      data: controlState.chartData,
    }));
    const chartLegend = computed(() => controlState.chartLegendData
      .map(({ barColor, name, color }) => ({
        color,
        label: name,
        borderColor: barColor,
      })));
    const preSelectedDatesForFilter = computed(() => controlState.selectedDates);

    const initControlData = async () => {
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/initSideMenuItems');
      if (!userState.profileSettings.name) {
        await store.dispatch('user/getUserProfileSettings');
      }
      await store.dispatch('control/getControlPageData');
    };

    const inspectionTypeSelectHandler = async (source) => {
      if (!source) {
        return;
      }
      selectedInspectionType.value = selectedInspectionType.value.id === source.id ? {} : source;
      await store.dispatch('control/setFilter', {
        name: 'source',
        value: selectedInspectionType.value.id,
      });
    };

    const filterByDate = (dates) => {
      store.dispatch('control/setSelectedDates', {
        dateStart: dates[0],
        dateEnd: dates[1],
      });
      store.dispatch('control/filterInspectionsByDate');
    };

    const locationChangeHandler = (id) => {
      store.dispatch('user/setCurrentLocation', id);
      initControlData();
    };

    initControlData();

    return {
      t,
      filterByDate,
      menuItems,
      userUnits,
      userLocations,
      currentUserLocation,
      averageResult,
      completedInspections,
      upcomingInspections,
      lastInspectionDate,
      finishedInspectionCount,
      inspectionsTabsLabels,
      inspectionTypes,
      locationChangeHandler,
      inspectionTypeSelectHandler,
      chartData,
      chartLegend,
      preSelectedDatesForFilter,
      userManagerData,
    };
  },
};
</script>

<style lang="scss">
.control-page {
  &__info {
    margin-top: 8px;
    max-width: 740px;
    line-height: 18px;
    font-size: $font-size-regular;
    color: $grey800;
  }

  &__block {
    margin-top: 60px;

    .title-secondary {
      margin-bottom: 12px;
    }

    .tabs {
      &__btn-area {
        .filter-dropdown {
          margin-right: 25px;
        }
      }
    }

    .info-chart__wrapper {
      margin-top: 0;
    }
  }

  &__check-cards {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid $grey200;

    &-item {
      width: 50%;
      border-bottom: 1px solid $grey200;

      &:nth-child(even) {
        border-left: 1px solid $grey200;
        padding-left: 12px;
      }
    }

    .stub-block-no-data__wrapper {
      margin-top: 70px;
    }
  }

  &__stub {
    padding: 20px 0 20px 10px;
    font-weight: $font-weight-medium;
    font-size: $font-size-medium;
    color: $main-black;
  }

  &__buttons-wrapper {
    display: flex;
    align-items: center;
  }

  &__chart-legend-text {
    color: $grey800;
    font-size: $font-size-regular;
    font-weight: $font-weight-regular;
    line-height: 18px;
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .control-page {
    &__content {
      .summary-table {
        &__row {
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
        }

        &__block {
          width: 50%;
          flex-grow: 0;
          border-bottom: 1px solid $grey200;

          &:nth-child(3) {
            border-bottom: none;
            border-top: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .control-page {
    &__check-cards {
      &-item {
        width: 100%;

        &:nth-child(even) {
          border-left: none;
          padding-left: 0;
        }
      }
    }

    &__block {
      .title-secondary {
        line-height: 24px;
        font-size: $font-size-title-small;
      }
    }

    &__content {
      .summary-table {
        &__row {
          flex-direction: column;
          flex-wrap: nowrap;
        }

        &__block {
          width: 100%;
          flex-grow: 1;
          border-bottom: none;

          &:nth-child(3) {
            border-top: 1px solid $grey200;
          }
        }
      }
    }
  }
}
</style>
