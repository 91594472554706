<template>
  <booking-table-stub v-if="!isFirstLoadingFinished"/>
  <div
    v-else
    class="booking-table"
    :class="{
      'booking-table--is-loading': isLoading,
    }"
  >
    <div class="booking-table__legend">
      <div class="booking-table__header">
        <div class="booking-table__current-day">
          <span class="booking-table__label">{{ $t('today') }}</span>
          <div class="booking-table__date">
            <span class="booking-table__date-icon"></span>
            <span class="booking-table__date-text">{{ formattedCurrentDate }}</span>
          </div>
        </div>
      </div>

      <div class="booking-table__groups">
        <div
          v-for="(group, index) in groupedUnits"
          :key="index + getRandomKey"
          class="booking-table__groups-item"
        >
          <div class="booking-table__cell booking-table__cell--legend booking-table__cell--header">
            {{ locale === 'en' ? group.nameEn :  group.name }}
          </div>
          <div
            v-for="(unit, index) in group.units"
            :key="index + getRandomKey"
            class="booking-table__cell booking-table__cell--legend"
          >
            <span class="booking-table__unit-name">{{ locale === 'en' ? unit.nameEn : unit.name }}</span>
            <span
              v-if="unit.unitNumber"
              class="booking-table__unit-number"
            >
              {{ unit.unitNumber }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <main-grid
      :grouped-units="groupedUnits"
      :timeline="timeline"
      :is-loading="isLoading"
      :is-hourly="isHourly"
      :is-periphery="isPeriphery"
      :filters="filters"
      @scrolled-to-start="onScrolledToStartHandler"
      @scrolled-to-end="onScrolledToEndHandler"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { prepareShortMonthDate } from '@/helpers/datesAndTime';
import BookingTableStub from '@/ui-kit/BookingTableStub/BookingTableStub.vue';
import { useI18n } from 'vue-i18n';
import MainGrid from './partials/main-grid.vue';

export default {
  name: 'BookingTable',
  components: {
    MainGrid,
    BookingTableStub,
  },
  emits: ['scrolledToStart', 'scrolledToEnd'],
  props: {
    currentDate: {
      type: Number,
    },
    dateStart: {
      type: Number,
      required: true,
    },
    dateEnd: {
      type: Number,
      required: true,
    },
    groupedUnits: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    timeline: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isFirstLoadingFinished: {
      type: Boolean,
      default: false,
    },
    isHourly: {
      type: Boolean,
      default: false,
    },
    isPeriphery: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { locale } = useI18n();
    const getRandomKey = computed(() => Math.ceil(Math.random() * 1000000));
    const formattedCurrentDate = ref(prepareShortMonthDate(new Date().getTime()));

    const onScrolledToStartHandler = () => {
      emit('scrolledToStart');
    };

    const onScrolledToEndHandler = () => {
      emit('scrolledToEnd');
    };

    return {
      locale,
      getRandomKey,
      formattedCurrentDate,
      onScrolledToEndHandler,
      onScrolledToStartHandler,
    };
  },
};
</script>

<style lang="scss">
@import './BookingTable.scss';
</style>
