<template>
  <nav class="main-side-menu">
    <ul class="main-side-menu__box">
      <li
        v-for="item in itemList"
        :key="item.id"
        class="main-side-menu__element"
      >
        <div
          v-if="item.subMenuLabel"
          :key="item.subMenuLabel"
          class="main-side-menu__submenu-wrapper"
        >
          <div class="main-side-menu__submenu-label">
            {{ t(item.subMenuLabel) }}
          </div>
          <ul class="main-side-menu__submenu-list">
            <li
              class="main-side-menu__submenu-element"
              v-for="subItem in item.subMenuItems"
              :key="subItem.id"
              :class="{
                'main-side-menu__submenu-element--state-active':subItem.id === activeItemId,
              }"
            >
              <router-link
                v-if="(!subItem.soon && !subItem.inactive) || (userState.profileSettings.id === 4 && userUnits.length ) "
                :to="subItem.path"
                class="main-side-menu__link"
                :class="{
                  'main-side-menu__link--state-active': subItem.id === activeItemId,
                }"
              >
                <div
                  class="
                    main-side-menu__submenu-label
                    main-side-menu__submenu-label--mobile
                  "
                >
                  {{ t(item.subMenuLabel) }}
                </div>
                {{ t(subItem.label) }}
              </router-link>
              <div
                v-else-if="subItem.inactive"
                class="main-side-menu__submenu-element"
              >
                <div
                  class="
                    main-side-menu__submenu-label
                    main-side-menu__submenu-label--mobile
                  "
                >
                  {{ t(item.subMenuLabel) }}
                </div>
                <info-header
                  class="main-side-menu__link main-side-menu__link--state-inactive"
                  underlineDisabled
                  :text="t(subItem.label)"
                  :popupData="{
                    header: t(subItem.tooltip.header),
                    text: t(subItem.tooltip.text, { msg: t('unit', 3) }),
                  }"
                />
              </div>
              <span
                v-else
                class="main-side-menu__link"
                :class="{
                  'main-side-menu__link--state-soon': subItem.soon,
                }"
              >
                {{ t(subItem.label) }}
              </span>
            </li>
          </ul>
        </div>
        <router-link
          v-else-if="!item.soon && !item.inactive"
          :to="item.path"
          class="main-side-menu__link"
          :class="{
            'main-side-menu__link--state-active': item.id === activeItemId,
          }"
        >
          {{ t(item.label) }}
        </router-link>
        <info-header
          v-else-if="item.inactive"
          class="main-side-menu__link main-side-menu__link--state-inactive"
          underlineDisabled
          :text="t(item.label)"
          :popupData="{
                    header: t(item.tooltip.header, t('unit', 3)),
                    text: t(item.tooltip.text),
                  }"
        />
        <span
          v-else
          class="main-side-menu__link"
          :class="{
            'main-side-menu__link--state-soon': item.soon,
          }"
        >
          {{ t(item.label) }}
        </span>
      </li>
    </ul>
  </nav>
</template>

<script>
import InfoHeader from '@/ui-kit/InfoHeader/InfoHeader.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name: 'mainSideMenu',
  props: {
    itemList: {
      type: Array,
      default: () => [],
    },
    activeItemId: {
      type: String,
      default: '',
    },
  },
  components: {
    InfoHeader,
  },
  setup() {
    const { t, tc } = useI18n();
    const store = useStore();
    const userState = store.state.user;
    const userUnits = computed(() => userState.units);

    console.log(userUnits, 'userUnits');
    return {
      t,
      tc,
      userState,
      userUnits,
    };
  },
};
</script>

<style lang="scss">
.main-side-menu {
  position: relative;
  display: flex;
  flex-direction: column;

  &__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    list-style: none;
    border-left: 1px solid #eff2f6;
  }

  &__element {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    box-sizing: border-box;
    text-decoration: none;
    font-size: $font-size-regular;
    font-weight: $font-weight-medium;
    line-height: 18px;
    height: auto;
    padding: 0 0 0 10px;
    margin-left: -1px;
    color: $main-black;
    border-left: 1px solid transparent;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
      border-left-color: $main-black;
    }

    &--state-active {
      border-left: 2px solid $blue500;
      color: $blue500;
      font-weight: $font-weight-bold;
      pointer-events: none;
      cursor: default;
    }

    &--state-soon {
      color: $grey700;

      &:before {
        content: 'скоро';
        display: inline-block;
        padding: 4px 2px;
        margin-right: 4px;
        background-color: $grey400;
        border-radius: 8px;
        color: #fff;
        font-weight: 700;
        font-size: 7px;
        text-transform: uppercase;
        line-height: 3px;
      }

      &:hover {
        cursor: default;
        opacity: 1;
        border-left-color: transparent;
      }
    }

    &--state-inactive {
      &:hover {
        border-left-color: transparent;
      }
    }
  }

  &__submenu {
    &-wrapper {
      margin-top: 8px;
      display: flex;
      flex-direction: column;
    }

    &-label {
      color: $grey800;
      font-size: $font-size-small;
      line-height: 16px;
      font-weight: $font-weight-medium;
      padding: 0 0 0 12px;

      &--mobile {
        display: none;
      }
    }

    &-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    &-element {
      margin: 0;
      padding: 0;

      .main-side-menu__link {
        padding: 0 0 0 26px;
        margin-top: 8px;
      }

      .info-header__wrapper {
        color: $grey700;

        &:hover {
          cursor: default;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .main-side-menu {
    justify-content: center;
    padding: 10px 20px;
    box-sizing: border-box;
    background: #fff;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;

    }

    &__box {
      flex-direction: row;
      align-items: flex-end;
    }

    &__element {
      border: none;
      padding: 0;
      margin-right: 30px;
      border-radius: 24px;
      flex-shrink: 0;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    &__link {
      margin: 0;
      padding: 0;
      border-left: none;
      color: $grey900;

      &--state-active {
        border-left: none;
        font-weight: $font-weight-medium;
        color: $blue500;
      }

      &--state-soon {
        color: $grey700;
      }
    }

    &__submenu {

      &-element {
        margin-left: 30px;

        &:first-child {
          margin-left: 0;
        }

        .main-side-menu__link {
          display: flex;
          flex-direction: column;
          margin: 0;
          padding: 0;
        }

        &--state-active {
          .main-side-menu__submenu-label {
            color: $blue400;

            &--mobile {
              color: $grey800;
            }
          }
        }
      }

      &-wrapper {
        margin-top: 0;
      }

      &-list {
        display: flex;
      }

      &-label {
        display: none;
        padding: 0;

        &--mobile {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .main-side-menu {
    border-bottom: 1px solid $blue100;

    &__element {
      margin-right: 4px;
    }

    &__box {
      align-items: center;
      border-left: 0;
    }

    &__link {
      height: 40px;
      padding: 0 10px;
      border-radius: 24px;
      background: $blue100;
      font-size: $font-size-small;

      &--state-active {
        background: $blue100;
      }
    }

    &__submenu {
      &-element {
        height: 40px;
        margin-left: 4px;
        background: $grey200;
        border-radius: 24px;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &--state-active {
          background: $blue100;
        }

        &:first-child {
          margin-left: 0;
        }

        .main-side-menu__link {
          height: auto;
          background: none;
          border-radius: 0;
          padding: 0;

          &--state-active {
            background: none;
          }
        }

        .info-header__wrapper {
          .info-header {
            font-size: $font-size-small;
          }
        }
      }

      &-label {
        &--mobile {
          font-size: $font-size-tiny;
        }
      }
    }
  }
}
</style>
