<template>
  <div class="faq-question">
    <TransitionGroup
      name="slide"
      tag="ul"
      class="faq-question__list"
    >
      <li
        v-for="(item, index) in dataArray"
        :key="item.id"
        class="faq-question__list-item"
        :class="{
          'faq-question__list-item--active': item.isOpen,
        }"
      >
        <div
          class="faq-question__question-wrapper"
          @click="toggleAnswer(index)"
        >
          <p
            class="faq-question__question"
            v-html=" (locale === 'en' ? item.questionEn : item.question)"
          ></p>
          <button
            class="faq-question__button"
            :class="{'faq-question__button-state-active':item.isOpen}"
          >
            <svg
              width="11"
              height="6"
              viewBox="0 0 11 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.49998 6C5.30284 6 5.10572 5.92797 4.95542
                5.78422L0.225656 1.25832C-0.0752187
                0.970413 -0.0752187 0.503627 0.225656 0.21584C0.526409
                -0.0719468 1.01413 -0.0719468
                1.31503 0.21584L5.49998 4.22061L9.68496 0.21598C9.98584
                -0.0718069 10.4735 -0.0718069
                10.7742 0.21598C11.0753 0.503767 11.0753 0.970553
                10.7742 1.25846L6.04455
                5.78436C5.89417 5.92814 5.69705 6 5.49998 6Z"
                fill="#424551"
              />
            </svg>
          </button>
        </div>
        <Transition name="inner">
          <div
            class="faq-question__answer-wrapper"
            v-if="item.isOpen"
          >
            <p
              class="faq-question__answer"
              v-html=" (locale === 'en' ? item.answerEn : item.answer)"
            ></p>
          </div>
        </Transition>
      </li>
    </TransitionGroup>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const prepareArray = (arr) => !arr.length
  ? []
  : arr.map((item) => ({
    ...item,
    isOpen: false,
  }));

export default {
  name: 'FaqQuestion',
  props: {
    questionsArray: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { locale } = useI18n();
    const dataArray = ref(prepareArray(props.questionsArray));

    const toggleAnswer = (index) => {
      dataArray.value[index].isOpen = !dataArray.value[index].isOpen;
    };

    watch(() => props.questionsArray, () => {
      dataArray.value = prepareArray(props.questionsArray);
    });

    return {
      locale,
      dataArray,
      toggleAnswer,
    };
  },
};
</script>

<style scoped lang="scss">
.faq-question {
  width: 100%;
  position: relative;

  &__question-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }
  }

  &__question {
    margin: 0;
    padding: 0;
    line-height: 20px;
    font-size: $font-size-regular;
    font-weight: $font-weight-medium;
    color: $main-black;
  }

  &__answer-wrapper {
    width: 100%;
    padding: 5px 20px 20px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid $grey200;
    transform-origin: top;
  }

  &__answer {
    margin: 0;
    padding: 0;
    line-height: 20px;
    font-size: $font-size-regular;
    font-weight: $font-weight-regular;
    color: $grey900;
  }

  &__button {
    border: none;
    background-color: transparent;
    transition: 0.4s;

    &:hover {
      cursor: pointer;
    }

    &-state-active {
      transform: rotate(180deg);

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__list-item {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    border-top: 1px solid $grey200;
    border-right: 1px solid $grey200;
    border-left: 1px solid $grey200;
    transform-origin: top;

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom: 1px solid $grey200;
      border-bottom-left-radius: 8px;
    }

    &--active {
      background-color: $grey100;
    }
  }
}

.slide-move,
.inner-enter-active,
.inner-leave-active {
  transition: all 0.4s ease;
}

.inner-enter-from,
.inner-leave-to {
  z-index: -1;
  opacity: 0;
  transform: scale(1,0);
}
.inner-leave-active {
  position: absolute;
}

</style>
