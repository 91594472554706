<template>
  <dashboard-layout
    class="inventory-page"
    :locations-list="userLocations"
    :current-location="currentUserLocation"
    @location-change="locationChangeHandler"
  >
    <template #outer-content>
      <ui-tabs
        :list="userLocations"
        :preselected-tab-id="currentUserLocation ? currentUserLocation.id : null"
        @tab-change="locationChangeHandler"
      />
    </template>
    <template #side-content>
      <main-side-menu
        :item-list="menuItems"
        active-item-id="inventory"
      />
      <user-actives
        :units-list="userUnits"
      />
      <user-manager
        :manager-data="userManagerData"
      />
    </template>
    <template #main-content>
      <div class="inventory-page__content-block">
        <div class="inventory-page__title-block">
          <title-main
            class="inventory-page__title"
            :text="t('inventoryPage.inventory')"
            icon
          >
            <template #icon>
              <img src="../assets/list-icon.svg" alt="list-icon">
            </template>
          </title-main>
        </div>
        <div class="inventory-page__content">
          <div class="inventory-page__controls">
            <ui-tabs
              :list="userUnitsTabs"
              :preselected-tab-id="userUnitsTabs[0] ? userUnitsTabs[0].id : 0"
              flat
              @tab-change="unitChangeHandler"
            />
            <div class="inventory-page__filters">
              <filter-dropdown
                v-if="locList && locList.length"
                :filter-name="t('space')"
                is-multiple-choice
                :filter-values="locList"
                @filter-select="locFilterHandler"
              >
                <template #icon>
                  <svg width="9" height="8" viewBox="0 0 9 8"
                       fill="none" xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.58989 0H0.879307C0.708949 0.000134153 0.542294 0.0497129
            0.399565 0.142719C0.256837 0.235726 0.144173 0.368161 0.075246
            0.523951C0.00631945 0.679742 -0.0159059 0.85219 0.0112674 1.02037C0.0384408
            1.18854 0.113844 1.34522 0.228327 1.47137L3.13656 4.6698V7.68627C3.13658
            7.74523 3.15323 7.803 3.18458 7.85294C3.21593 7.90288 3.26072 7.94297 3.31381
            7.96862C3.35625 7.98955 3.40297 8.00029 3.45028 7.99999C3.52159 7.99994
            3.59075 7.9756 3.64636 7.93098L4.2346 7.46039L5.21499 6.67607C5.25168
            6.6467 5.28129 6.60946 5.30166 6.56711C5.32202 6.52475 5.33261 6.47836 5.33264
            6.43137V4.6698L8.24087 1.47137C8.35535 1.34522 8.43075 1.18854 8.45793
            1.02037C8.4851 0.85219 8.46288 0.679742 8.39395 0.523951C8.32502 0.368161
            8.21236 0.235726 8.06963 0.142719C7.9269 0.0497129 7.76025 0.000134153
            7.58989 0Z" fill="#89909E"/>
                  </svg>

                </template>
              </filter-dropdown>
            </div>
          </div>
          <div
            v-if="inventoryItemsKeys && inventoryItemsKeys.length"
            class="inventory-page__block-list"
          >
            <div
              v-for="inventoryItemId in inventoryItemsKeys"
              :key="inventoryItemId"
              class="inventory-page__block"
            >
              <h4
                v-if="inventoryItemsList[inventoryItemId]"
                class="inventory-page__block-title"
              >
                {{ inventoryItemsList[inventoryItemId].LocName }}
              </h4>

              <inventory-table
                v-if="inventoryItemsList[inventoryItemId]"
                :inventory-items="inventoryItemsList[inventoryItemId].items"
                @media-request="mediaRequestHandler"
              />
            </div>
          </div>
          <stub-block-no-data
            v-else
            :title="t('inventoryPage.stubTitle')"
            :sub-title="t('inventoryPage.stubSubTitle')"
          >
            <template #icon>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2 20V3H0V21C0 21.2652 0.105357 21.5196 0.292893 21.7071C0.48043 21.8946
                0.734784 22 1 22H24V20H2Z" fill="#89909E"/>
                <path d="M9 12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11
                8 11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4
                12V19H9V12Z" fill="#89909E"/>
                <path d="M16 8C16 7.73478 15.8946 7.48043 15.7071 7.29289C15.5196 7.10536 15.2652
                7 15 7H12C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11
                8V19H16V8Z" fill="#89909E"/>
                <path d="M23 4C23 3.73478 22.8946 3.48043 22.7071 3.29289C22.5196 3.10536 22.2652
                3 22 3H19C18.7348 3 18.4804 3.10536 18.2929 3.29289C18.1054 3.48043 18 3.73478 18
                4V19H23V4Z" fill="#89909E"/>
              </svg>
            </template>
          </stub-block-no-data>
        </div>
      </div>
      <modal-window
        :is-shown="isOpenModal"
        is-media
        @close-window="toggleModal"
      >
        <media-gallery
          v-if="slides && slides.length"
          :slides="slides"
        />
      </modal-window>
    </template>
  </dashboard-layout>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import MainSideMenu from '@/components/MainSideMenu/MainSideMenu.vue';
import UserActives from '@/components/UserActives/UserActives.vue';
import InventoryTable from '@/components/InventoryTable/InventoryTable.vue';
import UiTabs from '@/ui-kit/UiTabs/UiTabs.vue';
import ModalWindow from '@/components/ModalWindow/ModalWindow.vue';
import MediaGallery from '@/components/MediaGallery/MediaGallery.vue';
import FilterDropdown from '@/components/FilterDropdown/FilterDropdown.vue';
import StubBlockNoData from '@/components/StubBlockNoData/StubBlockNoData.vue';
import UserManager from '@/components/UserManager/UserManager.vue';
import { useI18n } from 'vue-i18n';
import translates from '@/views/translates';

export default {
  name: 'InventoryPage',
  components: {
    TitleMain,
    DashboardLayout,
    MainSideMenu,
    InventoryTable,
    UserActives,
    ModalWindow,
    MediaGallery,
    FilterDropdown,
    StubBlockNoData,
    UiTabs,
    UserManager,
  },
  setup() {
    const store = useStore();
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translates.en);
    mergeLocaleMessage('ru', translates.ru);
    const inventoryState = store.state.inventory;
    const userState = store.state.user;
    const userManagerData = computed(() => userState.managerData);
    const menuItems = computed(() => userState.sideMenuItems);
    const isInvestor = computed(() => userState.isInvestor);
    const userUnits = computed(() => userState.units);
    const userUnitsTabs = computed(() => userState.unitsByCurrentLocation.map((unit) => ({
      id: unit.unitId,
      label: unit.unitName,
    })));
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name, labelEn: location.nameEn })));
    const currentUserLocation = computed(() => userState.currentLocation);
    const inventoryItemsList = computed(() => inventoryState
      .inventoryItemsListGrouped);
    const slides = computed(() => inventoryState.inventoryItemImages);
    const locList = computed(() => inventoryItemsList.value
    && Object.keys(inventoryItemsList.value).length
      ? Object.keys(inventoryItemsList.value).map((item) => ({
        id: item,
        label: inventoryItemsList.value[item].LocName,
      }))
      : []);
    const inventoryItemsKeys = ref([]);

    const isOpenModal = ref(false);
    const toggleModal = () => {
      isOpenModal.value = !isOpenModal.value;
    };

    const initInventoryPage = async () => {
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/initSideMenuItems');
      if (!userState.profileSettings.name) {
        await store.dispatch('user/getUserProfileSettings');
      }
      await store.dispatch('inventory/setCurrentUnitId', userUnitsTabs.value[0].id);
      await store.dispatch('inventory/getInventoryItems');
      inventoryItemsKeys.value = Object.keys(inventoryItemsList.value);
    };

    const locationChangeHandler = (id) => {
      store.dispatch('user/setCurrentLocation', id);
      initInventoryPage();
    };

    const unitChangeHandler = async (unitId) => {
      store.dispatch('inventory/setCurrentUnitId', unitId);
      await store.dispatch('inventory/getInventoryItems');
      inventoryItemsKeys.value = Object.keys(inventoryItemsList.value);
    };

    const mediaRequestHandler = async (e) => {
      await store.dispatch('inventory/getInventoryItemImages', e);
      toggleModal();
    };

    const locFilterHandler = (e) => {
      if (e.length) {
        const updatedList = {};
        e.forEach((loc) => {
          updatedList[loc.id] = inventoryState.inventoryItemsListGrouped[loc.id];
        });
        inventoryItemsKeys.value = Object.keys(updatedList);
      } else {
        inventoryItemsKeys.value = Object.keys(inventoryItemsList.value);
      }
    };

    initInventoryPage();

    return {
      t,
      userUnits,
      menuItems,
      userLocations,
      currentUserLocation,
      inventoryItemsList,
      isInvestor,
      isOpenModal,
      userUnitsTabs,
      slides,
      inventoryItemsKeys,
      locList,
      unitChangeHandler,
      locFilterHandler,
      toggleModal,
      locationChangeHandler,
      mediaRequestHandler,
      userManagerData,
    };
  },
};
</script>

<style lang="scss">
.inventory-page {
  &__content {
    min-height: calc(100vh - 240px);

    .stub-block-no-data__wrapper {
      margin-top: 50px;
    }
  }

  &__content-block {
    width: 100%;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
  }

  &__table {
    margin-top: 16px;
    overflow: auto;
  }

  &__title {
    &-block {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;

      .info-card {
        margin-top: 16px;
      }
    }
  }

  &__subtitle {
    margin-top: 8px;
    color:$grey800;
    font-size: $font-size-regular;
    line-height: 18px;
    font-weight: $font-weight-regular;
  }

  &__controls {
    position: relative;
    margin-top: 24px;
  }

  &__filters {
    position: absolute;
    right: 0;
    top: 5px;

    .filter-dropdown {
      width: 200px;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__tabs-block {
    margin-top: 60px;

    .tabs__list {
      border-bottom: 1px solid $grey200;
    }
  }

  &__block {
    margin-top: 28px;
    overflow-x: auto;

    &:first-child {
      margin-top: 0;
    }

    &-title {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .inventory-page {
    &__table {
      margin-top: 24px;
    }

    &__filters {
      margin-top: 24px;
      position: relative;
      right: initial;
      top: initial;

      .filter-dropdown {
        width: initial;
        justify-content: flex-start;
      }
    }
  }
}
</style>
