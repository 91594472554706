import incomeModel from '@/models/income';
import { ONE_YEAR } from '@/constants/dates';
import { isObjectEmpty, openDownloadFilePage, updateMoneySum } from '@/helpers/common';
import { prepareRequestDate, prepareShortMonthDate } from '@/helpers/datesAndTime';

const prepareMonthlyRequestDataObject = (firstDate, secondDate) => ({
  first: prepareRequestDate(firstDate),
  second: prepareRequestDate(secondDate),
});

const prepareChartDates = (startDate, endDate) => {
  const obj = {};

  const startYear = new Date(startDate).getFullYear();
  const startMonth = new Date(startDate).getMonth() + 1;
  const endYear = new Date(endDate).getFullYear();
  const endMonth = new Date(endDate).getMonth() + 1;

  const formatDate = (year, month) => `${year}${month.toString().padStart(2, '0')}`;

  for (let year = startYear; year <= endYear; year++) {
    const monthStart = (year === startYear) ? startMonth : 1;
    const monthEnd = (year === endYear) ? endMonth : 12;

    for (let month = monthStart; month <= monthEnd; month++) {
      obj[formatDate(year, month)] = prepareShortMonthDate(`${year}/${month}/05`, { day: true });
    }
  }

  return obj;
};

const BAR_COLORS = ['#2B54E2', '#5FB6D6', '#F8D181', '#C0D7FA'];

export default {
  namespaced: true,
  state: () => ({
    averageMonthlyIncome: null,
    invested: null,
    totalIncome: null,
    payback: null,
    averagePercentForYear: null,
    averagePercentForMonth: null,
    incomeChartDates: {},
    monthlyData: [],
    preparedIncomeGraphicData: [],
    currentIncomeGraphicData: [],
    monthlyDataDates: null,
    monthlyRequestData: {
      yearMonthFrom: '',
      yearMonthTo: '',
    },
    currentMonthOfIncomeByUnits: {},
    isLoadingStatistics: false,
    incomeByUnits: [],
    incomeByUnitsDate: null,
    capitalizationGraphicDataAll: [],
    currentCapitalizationData: {},
    isLoading: false,
  }),
  mutations: {
    'set:income:statistic': function (state, statistic) {
      state.averageMonthlyIncome = statistic.averageMonthlyIncome;
      state.invested = statistic.invested;
      state.totalIncome = statistic.totalIncome;
      state.payback = statistic.payback;
      state.loanTermYearMonth = statistic.loanTermYearMonth ? prepareShortMonthDate(`${
        statistic.loanTermYearMonth.toString().slice(0, 4)
      }/${statistic.loanTermYearMonth.toString().slice(4, 6)}/02`, { day: true }) : '';
      state.averagePercentForYear = statistic.averagePercentForYear;
      state.averagePercentForMonth = statistic.averagePercentForMonth;
    },
    'set:income:monthly-data:dates': function (state, dates) {
      state.monthlyDataDates = dates;
    },
    'set:income:monthly-request-data': function (state, data) {
      state.monthlyRequestData = {
        yearMonthFrom: data.first,
        yearMonthTo: data.second,
      };
    },
    'set:isLoadingStatistics': function (state, value) {
      state.isLoadingStatistics = value;
    },
    'set:current:month:of:income:by:units': function (state, month) {
      state.currentMonthOfIncomeByUnits = month;
    },
    'set:income:chart-dates': function (state, { dateStart, dateEnd }) {
      state.incomeChartDates = prepareChartDates(dateStart, dateEnd);
    },
    'set:income:monthly-data': function (state, data) {
      state.monthlyData = data;
    },
    'set:income:graphic-data': function (state, { data, dates, isInvestor }) {
      if (!isInvestor) {
        state.preparedIncomeGraphicData = Object.values(dates).map((item) => {
          const itemIndex = data.findIndex((elem) => elem.date === item);
          if (data.find((elem) => elem.date === item)) {
            return {
              amount: data[itemIndex].amount,
              label: item,
              color: BAR_COLORS[0],
              name: data.length ? data[0].name : '',
            };
          }
          return {
            amount: 0,
            label: item,
            color: BAR_COLORS[0],
            name: data.length ? data[0].name : '',
          };
        });
      } else {
        state.preparedIncomeGraphicData = data.map((item, index) => ({
          id: index + 1,
          color: BAR_COLORS[index],
          name: item.part === '1' ? item.unitName : `${item.part}\u202F${item.unitName}`,
          nameEn: item.part === '1' ? item.unitNameEn : `${item.part}\u202F${item.unitNameEn}`,
          data: Object.values(dates).map((innerItem) => {
            const itemIndex = item.incomes.findIndex((elem) => elem.date === innerItem);
            if (item.incomes.find((elem) => elem.date === innerItem)) {
              return {
                amount: item.incomes[itemIndex].amount,
                label: innerItem,
              };
            }
            return {
              amount: 0,
              label: innerItem,
            };
          }),
        }));
      }
    },
    'set:income:selected-graphic-data': function (state, data) {
      state.currentIncomeGraphicData = data;
    },
    'set:income:by-units-date': function (state, date) {
      state.incomeByUnitsDate = date;
    },
    'set:income:by:units': function (state, incomeByUnits) {
      state.incomeByUnits = [...incomeByUnits];
    },
    'set:income:capitalization:graphic-all': function (state, data) {
      state.capitalizationGraphicDataAll = data;
    },
    'set:income:current:capitalization-data': function (state, data) {
      state.currentCapitalizationData = data;
    },
    'set:income:is-loading': function (state, value) {
      state.isLoading = value;
    },
  },
  actions: {
    async initIncome({
      commit, dispatch, state, rootState,
    }) {
      commit('set:income:is-loading', true);
      const currentDate = new Date().getTime();
      const dateStart = new Date(currentDate - ONE_YEAR).getTime();
      const dateEnd = new Date(currentDate).getTime();
      dispatch('setMonthlyDataDates', { dateStart, dateEnd });
      await dispatch('getIncomeStatistics');
      await dispatch('getIncomeMonthlyData');
      await dispatch('getCapitalizationGraphicData');
      dispatch(
        'setCurrentMonthOfIncomeByUnits',
        dateEnd,
      );
      if (rootState.user.isInvestor) {
        await dispatch('getIncomeByUnits');
      }
      if (state.capitalizationGraphicDataAll.length !== 0) {
        dispatch('setCurrentCapitalizationData', state.capitalizationGraphicDataAll[0]);
      }
      const data = state.monthlyData;
      const dates = state.incomeChartDates;
      await dispatch('setIncomeChartData', { data, dates });
      if (state.preparedIncomeGraphicData.length && rootState.user.isInvestor) {
        await dispatch('setCurrentIncomeGraphData', state.preparedIncomeGraphicData[0]);
      }
      commit('set:income:is-loading', false);
    },
    async getIncomeStatistics({ commit, rootState }, id) {
      commit('set:isLoadingStatistics', true);
      const resp = await
      incomeModel.getIncomeStatistics(id || rootState.user.currentLocation.id, rootState.user.isInvestor ? 'investor' : 'lender');
      if (resp.ok) {
        commit('set:income:statistic', resp.data);
        commit('set:isLoadingStatistics', false);
      }
    },
    async getIncomeMonthlyData({ commit, state, rootState }) {
      const resp = await incomeModel.getIncomeMonthlyData(
        {
          location: rootState.user.currentLocation.id,
          yearMonthFrom: state.monthlyRequestData.yearMonthFrom,
          yearMonthTo: state.monthlyRequestData.yearMonthTo,
        },
        rootState.user.isInvestor ? 'investor' : 'lender',
      );
      if (resp.ok) {
        if (!resp.data || !resp.data.length) {
          state.monthlyData = [];
        }
        if (!rootState.user.isInvestor) {
          const preparedLenderData = resp.data.map((item) => ({
            amount: item.amount,
            name: item.location.name,
            date: prepareShortMonthDate(`${
              item.yearMonth.toString().slice(0, 4)
            }/${item.yearMonth.toString().slice(4, 6)}/02`, { day: true }),
          })).reduce((acc, item) => {
            const foundIndex = acc.findIndex((x) => x.date === item.date);
            if (foundIndex > -1) {
              acc[foundIndex] = {
                amount: acc[foundIndex].amount + item.amount,
                date: acc[foundIndex].date,
                name: item.location.name,
              };
              return acc;
            }
            acc.push(item);
            return acc;
          }, []);

          commit('set:income:monthly-data', preparedLenderData);
        } else {
          const preparedInvestorData = resp.data.map((item) => ({
            ...item,
            incomes: item.incomes.map((innerItem) => ({
              amount: innerItem.amount,
              date: prepareShortMonthDate(`${
                innerItem.yearMonth.toString().slice(0, 4)
              }/${innerItem.yearMonth.toString().slice(4, 6)}/02`, { day: true }),
            })).reduce((acc, innerItem) => {
              const foundIndex = acc.findIndex((x) => x.date === innerItem.date);
              if (foundIndex > -1) {
                acc[foundIndex] = {
                  amount: acc[foundIndex].amount + innerItem.amount,
                  date: acc[foundIndex].date,
                };
                return acc;
              }
              acc.push(innerItem);
              return acc;
            }, []),
          }));

          commit('set:income:monthly-data', preparedInvestorData);
        }
      }
    },
    async getIncomeByUnits({ commit, state, rootState }) {
      if (!state.currentMonthOfIncomeByUnits) {
        commit('set:income:by:units', []);
        return;
      }

      const resp = await incomeModel.getIncomeMonthlyUnits(
        {
          location: rootState.user.currentLocation.id,
          yearMonth: state.incomeByUnitsDate,
        },
        rootState.user.isInvestor ? 'investor' : 'lender',
      );
      if (resp.ok) {
        commit('set:income:by:units', resp.data);
      }
    },
    async getCapitalizationGraphicData({ commit, rootState }) {
      const currentLocId = rootState.user.currentLocation.id;
      const currentLocUnits = rootState.user.units.filter(
        (item) => item.locationId === currentLocId,
      );
      const resp = await Promise.all(currentLocUnits.map(async (unit) => ({
        servData: await incomeModel.getIncomeCapitalizationGraphic({
          unitId: unit.unitId,
        }),
        unitId: unit.unitId,
        unitName: unit.unitName,
      })));
      const preparedArray = resp.reduce((acc, item) => {
        if (isObjectEmpty(item.servData) || !item.servData.data.graphic
          || item.servData.data.graphic.length) {
          return acc;
        }
        const preparedSeries = item.servData.data.graphic.reduce((seriesAcc, innerItem) => {
          if (!innerItem.capitalizationDate) {
            return seriesAcc;
          }
          const preparedObject = {
            date: prepareShortMonthDate(innerItem.capitalizationDate, { day: true }),
            amount: innerItem.capitalizationPrice,
            tooltipDate: prepareShortMonthDate(innerItem.capitalizationDate, { day: true }),
          };
          seriesAcc.push(preparedObject);
          return seriesAcc;
        }, []);

        const labels = preparedSeries.reduce((labelsAcc, labelItem, index) => {
          if (index === 0) {
            labelsAcc.push(labelItem.date);
            return labelsAcc;
          } if (index === preparedSeries.length - 1) {
            labelsAcc.push(labelItem.date);
            return labelsAcc;
          }
          labelsAcc.push('');
          return labelsAcc;
        }, []);

        const preparedObject = {
          id: item.unitId,
          name: item.unitName,
          capName: item.servData.data.capitalizationName,
          capDate: prepareShortMonthDate(
            item.servData.data.capitalizationDate,
            { day: true, notShort: true },
          ),
          capPrice: `${updateMoneySum(item.servData.data.capitalizationPrice)}\u202F₽`,
          data: {
            series: preparedSeries,
            preparedLabels: labels,
          },
        };
        acc.push(preparedObject);
        return acc;
      }, []);
      commit('set:income:capitalization:graphic-all', preparedArray);
    },
    async downloadIncomeTable({ state }, locationId) {
      const { blob, fileName } = await
      incomeModel.getBlobOfIncomeXls(
        locationId,
        state.isInvestor ? 'investor' : 'lender',
      );

      if (blob) {
        openDownloadFilePage(blob, fileName);
      }
    },
    async setMonthlyDataDates({ commit, state, dispatch }, { dateStart, dateEnd }) {
      commit('set:income:is-loading', true);
      commit('set:income:monthly-data:dates', [dateStart, dateEnd]);
      const updatedmonthlyRequestDataObject = prepareMonthlyRequestDataObject(dateStart, dateEnd);
      await dispatch('setMonthlyRequestData', updatedmonthlyRequestDataObject);
      await dispatch('setIncomeChartDates', { dateStart, dateEnd });
      await dispatch('getIncomeMonthlyData');
      const data = state.monthlyData;
      const dates = state.incomeChartDates;
      await dispatch('setIncomeChartData', { data, dates });
      await commit('set:income:is-loading', false);
    },
    setIncomeChartDates({ commit }, { dateStart, dateEnd }) {
      commit('set:income:chart-dates', { dateStart, dateEnd });
    },
    setIncomeChartData({ commit, rootState }, { data, dates }) {
      commit('set:income:graphic-data', { data, dates, isInvestor: rootState.user.isInvestor });
    },
    setCurrentMonthOfIncomeByUnits({ commit, dispatch }, month) {
      commit('set:current:month:of:income:by:units', month);
      const unitIncomeRequestDataObject = prepareRequestDate(month);
      dispatch('setIncomeByUnitsDate', unitIncomeRequestDataObject);
    },
    setMonthlyRequestData({ commit }, dataObject) {
      commit('set:income:monthly-request-data', dataObject);
    },
    setIncomeByUnitsDate({ commit }, date) {
      commit('set:income:by-units-date', date);
    },
    setCurrentCapitalizationData({ commit }, dataObject) {
      commit('set:income:current:capitalization-data', dataObject);
    },
    setCurrentIncomeGraphData({ commit }, dataObject) {
      const preparedArray = [];
      preparedArray.push(dataObject);
      commit('set:income:selected-graphic-data', preparedArray);
    },
    setSelectedCapitalizationData({ commit, state }, chartDataId) {
      const preparedData = state.capitalizationGraphicDataAll
        .find((item) => item.id === chartDataId);
      commit('set:income:current:capitalization-data', preparedData);
    },
    setSelectedIncomeGraphData({ commit, state }, chartDataId) {
      const preparedData = [];
      const selectedData = state.preparedIncomeGraphicData
        .find((item) => item.id === chartDataId);
      preparedData.push(selectedData);
      commit('set:income:selected-graphic-data', preparedData);
    },
  },
};
