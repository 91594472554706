import { createRouter, createWebHistory } from 'vue-router';
import {
  USER_TOKEN_KEY,
  PASSWORD_RECOVERY_VIEW,
  LOGIN_VIEW,
  SIGNUP_VIEW,
  SIGNUP_SUCCESS_VIEW,
  SIGNUP_PASSWORD_CREATE_VIEW,
  RECOVERY_PASSWORD_CREATE_VIEW,
  RECOVERY_SUCCESS_VIEW,
} from '@/constants/auth';
import BookingPage from '@/views/BookingPage.vue';
import PeripheryPage from '@/views/PeripheryPage.vue';
import IncomePage from '@/views/IncomePage.vue';
import ProfilePage from '@/views/ProfilePage.vue';
import NewsPage from '@/views/NewsPage.vue';
import UnitsPage from '@/views/UnitsPage.vue';
import OutcomePage from '@/views/OutcomePage.vue';
import OutcomePeripheryPage from '@/views/OutcomePeripheryPage.vue';
import DashboardPage from '@/views/DashboardPage.vue';
import PromoPage from '@/views/PromoPage.vue';
import FaqPage from '@/views/FaqPage.vue';
import InvalidPage from '@/views/InvalidPage.vue';
import TimelinePage from '@/views/TimelinePage.vue';
import ControlPage from '@/views/ControlPage.vue';
import InspectionPage from '@/views/InspectionPage.vue';
import DocumentsPage from '@/views/DocumentsPage.vue';
import ReportsPage from '@/views/ReportsPage.vue';
import InventoryPage from '@/views/InventoryPage.vue';
import FinancialReportPage from '@/views/FinancialReport.vue';
import CamerasPage from '@/views/CamerasPage.vue';

const ONLY_PUBLIC_ROUTES = [
  'login',
  'sign-up',
  'sign-up-success',
  'sign-up-password',
  'password-recovery',
  'recovery-success',
  'recovered-password',
];

const routes = [
  {
    path: '/',
    name: 'home',
    component: DashboardPage,
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AuthPage.vue'),
    props: {
      currentView: LOGIN_VIEW,
    },
  },
  {
    path: '/password-recovery',
    name: 'password-recovery',
    component: () => import('../views/AuthPage.vue'),
    props: {
      currentView: PASSWORD_RECOVERY_VIEW,
    },
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import('../views/AuthPage.vue'),
    props: {
      currentView: SIGNUP_VIEW,
    },
  },
  {
    path: '/sign-up-success',
    name: 'sign-up-success',
    component: () => import('../views/AuthPage.vue'),
    props: {
      currentView: SIGNUP_SUCCESS_VIEW,
    },
  },
  {
    path: '/recovery-success',
    name: 'recovery-success',
    component: () => import('../views/AuthPage.vue'),
    props: {
      currentView: RECOVERY_SUCCESS_VIEW,
    },
  },
  {
    path: '/activation/:link',
    name: 'sign-up-password',
    component: () => import('../views/AuthPage.vue'),
    props: {
      currentView: SIGNUP_PASSWORD_CREATE_VIEW,
    },
  },
  {
    path: '/recovery/:link',
    name: 'recovered-password',
    component: () => import('../views/AuthPage.vue'),
    props: {
      currentView: RECOVERY_PASSWORD_CREATE_VIEW,
    },
  },
  {
    path: '/dashboard/booking/',
    name: 'booking',
    component: BookingPage,
  },
  {
    path: '/dashboard/booking-periphery/',
    name: 'booking-periphery',
    component: PeripheryPage,
  },
  {
    path: '/dashboard/income/',
    name: 'income',
    component: IncomePage,
  },
  {
    path: '/dashboard/outcome/',
    name: 'outcome',
    component: OutcomePage,
  },
  {
    path: '/dashboard/outcome-periphery/',
    name: 'outcome-periphery',
    component: OutcomePeripheryPage,
  },
  {
    path: '/dashboard/timeline/',
    name: 'timeline',
    component: TimelinePage,
  },
  {
    path: '/dashboard/reports/',
    name: 'reports',
    component: ReportsPage,
  },
  {
    path: '/dashboard/control/',
    name: 'control',
    component: ControlPage,
  },
  {
    path: '/dashboard/control/inspection/:id',
    name: 'inspection',
    component: InspectionPage,
  },
  {
    path: '/dashboard/',
    name: 'main',
    component: DashboardPage,
  },
  {
    path: '/profile/',
    name: 'profile',
    component: ProfilePage,
  },
  {
    path: '/units/',
    name: 'units',
    component: UnitsPage,
  },
  {
    path: '/news/',
    name: 'news',
    component: NewsPage,
  },
  {
    path: '/promo/',
    name: 'promo',
    component: PromoPage,
  },
  {
    path: '/faq/',
    name: 'faq',
    component: FaqPage,
  },
  {
    path: '/documents/',
    name: 'documents',
    component: DocumentsPage,
  },
  {
    path: '/inventory/',
    name: 'inventory',
    component: InventoryPage,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'invalid',
    component: InvalidPage,
  },
  {
    path: '/dashboard/report-2024',
    name: 'financial-report',
    component: FinancialReportPage,
  },
  {
    path: '/dashboard/cameras',
    name: 'cameras',
    component: CamerasPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        }

        if (to.hash) {
          resolve({
            el: to.hash, x: 0, y: 20, behavior: 'smooth',
          });
        } else {
          resolve({ x: 0, y: 100 });
        }
      }, 500);
    });
  },
});

router.beforeEach((to, from, next) => {
  const isOnlyPublicRoute = ONLY_PUBLIC_ROUTES.includes(to.name);
  const isUserLoggedIn = localStorage.getItem(USER_TOKEN_KEY);

  if (isOnlyPublicRoute) {
    return isUserLoggedIn ? next('/') : next();
  }

  return isUserLoggedIn ? next() : next('login');
});

export default router;
