<template>
  <div
    class="user-manager"
  >
    <div class="user-manager__title">
      {{t("yourManager")}} {{ locale === "en" ? managerData.nameEn : managerData.name }}
    </div>
    <ul class="user-manager__list">
      <li class="user-manager__list-item">
        <a :href="`tel:${managerData.phone}`">
          <span class="user-manager__text">
            {{ managerData.preparedPhone }}
          </span>
        </a>
      </li>
      <li class="user-manager__list-item">
        <a :href="`mailto:${managerData.email}`">
          <span class="user-manager__text">
            {{ managerData.email }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  name: 'UserManager',
  props: {
    managerData: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { t, locale } = useI18n();

    return {
      t,
      locale,
    };
  },
};
</script>

<style lang="scss">
.user-manager {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
  }

  &__list {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 16px 0 0;
    margin: 12px 0 0;
    list-style: none;

    &-item {
      display: flex;
      width: fit-content;
      max-width: 100%;
      align-items: center;
      padding: 4px 12px;
      margin-top: 4px;
      border-radius: 4px;
      background: $grey100;
      border: 1px solid $grey200;

      a {
        text-decoration: none;
        color: $grey900;
        font-size: $font-size-small;
        font-weight: $font-weight-regular;
      }

      &:first-child {
        margin-top: 0;
      }

      &:hover {
        cursor: pointer;
        background-color: $blue500;

        a {
          color: $white;
        }
      }
    }
  }

  &__text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
}
</style>
