<template>
  <dashboard-layout
    class="outcome-page"
    :locations-list="userLocations"
    :current-location="currentUserLocation"
    @location-change="locationChangeHandler"
  >
    <template #outer-content>
      <ui-tabs
        :list="userLocations"
        :preselected-tab-id="currentUserLocation ? currentUserLocation.id : null"
        @tab-change="locationChangeHandler"
      />
    </template>
    <template #side-content>
      <main-side-menu
        :item-list="menuItems"
        active-item-id="income-outcome-rooms"
      />
      <user-actives
        :units-list="userUnits"
      />
      <user-manager
        :manager-data="userManagerData"
      />
    </template>
    <template #main-content>
      <div class="outcome-page__content-block">
        <div class="outcome-page__title-block">
          <div class="outcome-page__title-block-text-area">
            <title-main
              class="outcome-page__title"
              :text="t('outcomePage.title')"
              icon
            >
              <template #icon>
                <img src="../assets/circle-graph-icon.svg" alt="dashboard-icon">
              </template>
            </title-main>
<!--            <div class="outcome-page__subtitle">-->
<!--              {{ t('outcomePage.description') }}-->
<!--            </div>-->
          </div>
          <div class="outcome-page__controls">
            <div class="outcome-page__controls-item">
              <filter-date
                calendar-only
                is-month-picker
                no-day-button
                arrow-icon
                @filter-date-select="getIncomeOutcomeByMonth"
              />
            </div>
          </div>
        </div>
        <summary-table>
<!--          <stub-block-no-data-->
<!--            :title="t('outcomePage.stubTitle')"-->
<!--          >-->
<!--            <template #icon>-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">-->
<!--                <path d="M2 20V3H0V21C0 21.2652 0.105357 21.5196 0.292893 21.7071C0.48043 21.8946-->
<!--                0.734784 22 1 22H24V20H2Z" fill="#89909E"/>-->
<!--                <path d="M9 12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11-->
<!--                8 11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4-->
<!--                12V19H9V12Z" fill="#89909E"/>-->
<!--                <path d="M16 8C16 7.73478 15.8946 7.48043 15.7071 7.29289C15.5196 7.10536 15.2652-->
<!--                7 15 7H12C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11-->
<!--                8V19H16V8Z" fill="#89909E"/>-->
<!--                <path d="M23 4C23 3.73478 22.8946 3.48043 22.7071 3.29289C22.5196 3.10536 22.2652-->
<!--                3 22 3H19C18.7348 3 18.4804 3.10536 18.2929 3.29289C18.1054 3.48043 18 3.73478 18-->
<!--                4V19H23V4Z" fill="#89909E"/>-->
<!--              </svg>-->
<!--            </template>-->
<!--          </stub-block-no-data>-->
       <div class="summary-table__row">
            <div class="summary-table__block">
              <summary-info
                header="Доходы комплекса"
                :firstInfo="isDemo ? '1 355 030 ₽': incomeTotal"
                :firstMetric="isDemo? '10': incomePercent"
              />
            </div>
            <div class="summary-table__block">
              <summary-info
                header="Расходы комплекса (OPEX)"
                :firstInfo="isDemo ? '850 000 ₽' : outcomeTotal"
                :secondInfo=" isDemo ? '41%' :outcomePercent"
                secondSubtitle="от валовой выручки"
                :popupData="outcomePopupData.opex"
              />
            </div>
          </div>
          <div class="summary-table__row">
            <div class="summary-table__block">
              <summary-info
                header="GOP"
                :firstInfo="isDemo ? '1 050 000 ₽' :gopData"
                :secondInfo="isDemo ? '59%' :gopPercent"
                secondSubtitle="от валовой выручки"
                :popupData="outcomePopupData.gop"
              />
            </div>
          </div>
        </summary-table>
        <div class="outcome-page__tabs-block">
          <ui-tabs
            :list="outcomeTabs"
            :preselectedTabId="outcomeTabs[0].id"
            hasContent
            flat
          >
            <!-- <template #buttons>
              <secondary-button
                label="Файл XLS"
                :is-disabled="haveNoData"
                @click="downloadHandler"
              >
                <template #iconBefore>
                  <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.46968 0.221406C5.76255 -0.0738527
                    6.23745 -0.0738527 6.53032
                    0.221406L9.53032 3.24567C9.8232 3.54094 9.8232
                    4.01965 9.53032 4.31491C9.23745
                    4.61017 8.76255 4.61017 8.46968 4.31491L6.75
                    2.58133V8.35035C6.75 8.76793 6.41423
                    9.10642 6 9.10642C5.58577 9.10642 5.25 8.76793
                    5.25 8.35035V2.58133L3.53033
                    4.31491C3.23744 4.61017 2.76256 4.61017 2.46967
                    4.31491C2.17678 4.01965 2.17678
                    3.54094 2.46967 3.24567L5.46968 0.221406ZM0.75
                    8.21967C1.16421 8.21967 1.5 8.55816
                    1.5 8.97574V10.4879H10.5V8.97574C10.5 8.55816
                    10.8358 8.21967 11.25 8.21967C11.6642
                    8.21967 12 8.55816 12 8.97574V10.4879C12 11.323
                    11.3285 12 10.5 12H1.5C0.671572 12
                    0 11.323 0 10.4879V8.97574C0 8.55816 0.33579
                    8.21967 0.75 8.21967Z"/>
                  </svg>
                </template>
              </secondary-button>
            </template> -->
            <template #income>
              <outcome-table
                :outcomes="isDemo ? incomes : []"
                isIncome
              />
            </template>
            <template #outcome>
              <outcome-table
                :outcomes=" isDemo ? outcomes : []"
              />
            </template>
          </ui-tabs>
        </div>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { updateMoneySum } from '@/helpers/common';
import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
import UiTabs from '@/ui-kit/UiTabs/UiTabs.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import MainSideMenu from '@/components/MainSideMenu/MainSideMenu.vue';
// import SecondaryButton from '@/ui-kit/SecondaryButton/SecondaryButton.vue';
import FilterDate from '@/components/FilterDate/FilterDate.vue';
import UserActives from '@/components/UserActives/UserActives.vue';
// import SummaryInfo from '@/components/SummaryInfo/SummaryInfo.vue';
import SummaryTable from '@/components/SummaryTable/SummaryTable.vue';
import OutcomeTable from '@/components/OutcomeTable/OutcomeTable.vue';
import UserManager from '@/components/UserManager/UserManager.vue';
import { OUTCOME_PAGE_TAB_TITLES, OUTCOME_PAGE_POPUPS_DATA } from '@/constants/outcome';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import translates from '@/views/translates';
import SummaryInfo from '@/components/SummaryInfo/SummaryInfo.vue';

export default {
  name: 'OutcomePage',
  components: {
    SummaryInfo,
    TitleMain,
    MainSideMenu,
    DashboardLayout,
    UiTabs,
    FilterDate,
    // SecondaryButton,
    UserActives,
    OutcomeTable,
    // SummaryInfo,
    SummaryTable,
    UserManager,
  },
  mounted() {
    window.scrollTo(0, 0); // Прокрутка к началу страницы при монтировании компонента
  },
  setup() {
    const store = useStore();
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translates.en);
    mergeLocaleMessage('ru', translates.ru);
    const outcomeState = store.state.outcome;
    const userState = store.state.user;
    const userManagerData = computed(() => userState.managerData);
    const menuItems = computed(() => userState.sideMenuItems);
    const isDemo = computed(() => userState.profileSettings.id === 4);
    const outcomeTabs = ref(OUTCOME_PAGE_TAB_TITLES.map(({ id }) => ({
      id,
      label: t(`outcomePage.${id}`),
    })));
    const outcomePopupData = ref(OUTCOME_PAGE_POPUPS_DATA);
    const userUnits = computed(() => userState.units);
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name, labelEn: location.nameEn })));
    const currentUserLocation = computed(() => userState.currentLocation);
    // const gopData = computed(() => (outcomeState.allData.gop
    // ? `${updateMoneySum(outcomeState.allData.gop)}\u202F₽`
    // : '0\u202F₽'));
    const gopData = computed(() => {
      if (outcomeState.allData.gop >= 0) {
        return (outcomeState.allData.gop
          ? `${updateMoneySum(outcomeState.allData.gop)}\u202F₽`
          : '0\u202F₽');
      }
      return (outcomeState.allData.gop
        ? `-${updateMoneySum(Math.abs(outcomeState.allData.gop))}\u202F₽`
        : '0\u202F₽');
    });
    const gopPercent = computed(() => `${outcomeState.allData.gopPercent}%`);
    const incomeTotal = computed(() => (outcomeState.allData.incomeTotal
      ? `${updateMoneySum(outcomeState.allData.incomeTotal)}\u202F₽`
      : '0\u202F₽'));
    const incomePercent = computed(() => `${outcomeState.allData.incomePercent}%`);
    const outcomeTotal = computed(() => (outcomeState.allData.outcomeTotal
      ? `${updateMoneySum(outcomeState.allData.outcomeTotal)}\u202F₽`
      : '0\u202F₽'));
    const outcomePercent = computed(() => `${outcomeState.allData.outcomePercent}%`);
    const incomesList = computed(() => outcomeState.incomesList);
    const outcomesList = computed(() => outcomeState.outcomesList);

    const initOutcomeData = async () => {
      store.dispatch('base/setCurrentPath', useRoute());
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/initSideMenuItems');
      if (!userState.profileSettings.name) {
        await store.dispatch('user/getUserProfileSettings');
      }
      store.dispatch('outcome/setDefaultData');
    };

    /* const downloadHandler = () => {
      store.dispatch('outcome/downloadOutcomeTable');
    };
    */
    const locationChangeHandler = (id) => {
      store.dispatch('user/setCurrentLocation', id);
      initOutcomeData();
    };

    const getIncomeOutcomeByMonth = ({ month, year }) => {
      store.dispatch('outcome/setSelectedData', { year, month });
    };

    initOutcomeData();

    const outcomes = [
      {
        date: '01.01.2022',
        category: { id: 372384, text: 'Амортизация' },
        article: 'Ежемесячный взнос на неожиданные поломки, на этом счету уже 1 000 000 ₽',
        amountText: '102 334 ₽',
      },
      {
        date: '02.01.2022',
        category: { id: 372384, text: 'Амортизация' },
        article: 'Проверка котлов',
        amountText: '54 764 ₽',
      },
      {
        date: '03.01.2022',
        category: { id: 372382, text: 'Сезонные траты' },
        article: 'Ежемесячный взнос на неожиданные поломки, на этом счету уже 1 000 000 ₽',
        amountText: '45 838 ₽',
      },
      {
        date: '03.01.2022',
        category: { id: 630158, text: 'Тех. обслуживание' },
        article: 'Елка 5 метров',
        amountText: '102 334 ₽',
      },
      {
        date: '03.01.2022',
        category: { id: 630158, text: 'Тех. обслуживание' },
        article: 'Платеж за елку к новому году',
        amountText: '20 100 ₽',
      },
      {
        date: '04.01.2022',
        category: { id: 372382, text: 'Сезонные траты' },
        article: 'Проверка котлов',
        amountText: '102 334 ₽',
      },
      {
        date: '23.01.2022',
        category: { id: 372390, text: 'Зимние траты' },
        article: 'Ежемесячный взнос на неожиданные поломки, на этом счету уже 1 000 000 ₽',
        amountText: '45 838 ₽',
      },
      {
        date: '23.01.2022',
        category: { id: 372390, text: 'Зимние траты' },
        article: 'Обработка растений средством от насекомых и грызунов. Следующая обработка через полгода',
        amountText: '45 000 ₽',
      },
      {
        date: '22.01.2022',
        category: { id: 372385, text: 'Внеплановые траты' },
        article: 'Елка 5 метров',
        amountText: '102 334 ₽',
      },
      {
        date: '22.01.2022',
        category: { id: 372385, text: 'Внеплановые траты' },
        article: 'Платеж за елку к новому году',
        amountText: '20 100 ₽',
      },
      {
        date: '22.01.2022',
        category: { id: 630158, text: 'Тех. обслуживание' },
        article: 'Проверка котлов',
        amountText: '102 334 ₽',
      },
      {
        date: '25.01.2022',
        category: { id: 372390, text: 'Зимние траты' },
        article: 'Ежемесячный взнос на неожиданные поломки, на этом счету уже 1 000 000 ₽',
        amountText: '45 838 ₽',
      },
      {
        date: '30.01.2022',
        category: { id: 372391, text: 'Ремонт' },
        article: 'Обработка растений средством от насекомых и грызунов. Следующая обработка через полгода',
        amountText: '45 000 ₽',
      },
      {
        date: '22.01.2022',
        category: { id: 372385, text: 'Внеплановые траты' },
        article: 'Платеж за елку к новому году',
        amountText: '20 100 ₽',
      },
      {
        date: '22.01.2022',
        category: { id: 630158, text: 'Тех. обслуживание' },
        article: 'Проверка котлов',
        amountText: '102 334 ₽',
      },
      {
        date: '25.01.2022',
        category: { id: 372390, text: 'Зимние траты' },
        article: 'Ежемесячный взнос на неожиданные поломки, на этом счету уже 1 000 000 ₽',
        amountText: '45 838 ₽',
      },
      {
        date: '30.01.2022',
        category: { id: 372391, text: 'Ремонт' },
        article: 'Обработка растений средством от насекомых и грызунов. Следующая обработка через полгода',
        amountText: '45 000 ₽',
      },
    ];

    const incomes = [
      {
        date: '30.01.2022',
        category: { id: 372391, text: 'Ремонт' },
        article: 'Обработка растений средством от насекомых и грызунов. Следующая обработка через полгода',
        amountText: '45 000 ₽',
      },
      {
        date: '23.01.2022',
        category: { id: 372390, text: 'Зимние траты' },
        article: 'Ежемесячный взнос на неожиданные поломки, на этом счету уже 1 000 000 ₽',
        amountText: '45 838 ₽',
      },
      {
        date: '02.01.2022',
        category: { id: 372384, text: 'Амортизация' },
        article: 'Проверка котлов',
        amountText: '54 764 ₽',
      },
      {
        date: '22.01.2022',
        category: { id: 372385, text: 'Внеплановые траты' },
        article: 'Елка 5 метров',
        amountText: '102 334 ₽',
      },
      {
        date: '03.01.2022',
        category: { id: 372382, text: 'Сезонные траты' },
        article: 'Ежемесячный взнос на неожиданные поломки, на этом счету уже 1 000 000 ₽',
        amountText: '45 838 ₽',
      },
      {
        date: '22.01.2022',
        category: { id: 630158, text: 'Тех. обслуживание' },
        article: 'Проверка котлов',
        amountText: '102 334 ₽',
      },
      {
        date: '04.01.2022',
        category: { id: 372382, text: 'Сезонные траты' },
        article: 'Проверка котлов',
        amountText: '102 334 ₽',
      },
      {
        date: '23.01.2022',
        category: { id: 372390, text: 'Зимние траты' },
        article: 'Обработка растений средством от насекомых и грызунов. Следующая обработка через полгода',
        amountText: '45 000 ₽',
      },
      {
        date: '03.01.2022',
        category: { id: 630158, text: 'Тех. обслуживание' },
        article: 'Елка 5 метров',
        amountText: '102 334 ₽',
      },
      {
        date: '25.01.2022',
        category: { id: 372390, text: 'Зимние траты' },
        article: 'Ежемесячный взнос на неожиданные поломки, на этом счету уже 1 000 000 ₽',
        amountText: '45 838 ₽',
      },
      {
        date: '22.01.2022',
        category: { id: 372385, text: 'Внеплановые траты' },
        article: 'Платеж за елку к новому году',
        amountText: '20 100 ₽',
      },
      {
        date: '30.01.2022',
        category: { id: 372391, text: 'Ремонт' },
        article: 'Обработка растений средством от насекомых и грызунов. Следующая обработка через полгода',
        amountText: '45 000 ₽',
      },
      {
        date: '01.01.2022',
        category: { id: 372384, text: 'Амортизация' },
        article: 'Ежемесячный взнос на неожиданные поломки, на этом счету уже 1 000 000 ₽',
        amountText: '102 334 ₽',
      },
      {
        date: '22.01.2022',
        category: { id: 372385, text: 'Внеплановые траты' },
        article: 'Платеж за елку к новому году',
        amountText: '20 100 ₽',
      },
      {
        date: '03.01.2022',
        category: { id: 630158, text: 'Тех. обслуживание' },
        article: 'Платеж за елку к новому году',
        amountText: '20 100 ₽',
      },
      {
        date: '25.01.2022',
        category: { id: 372390, text: 'Зимние траты' },
        article: 'Ежемесячный взнос на неожиданные поломки, на этом счету уже 1 000 000 ₽',
        amountText: '45 838 ₽',
      },
      {
        date: '22.01.2022',
        category: { id: 630158, text: 'Тех. обслуживание' },
        article: 'Проверка котлов',
        amountText: '102 334 ₽',
      },
    ];

    return {
      t,
      userUnits,
      menuItems,
      userLocations,
      currentUserLocation,
      outcomeTabs,
      gopData,
      gopPercent,
      incomeTotal,
      incomePercent,
      outcomeTotal,
      outcomePercent,
      incomesList,
      outcomesList,
      outcomePopupData,
      locationChangeHandler,
      // downloadHandler,
      getIncomeOutcomeByMonth,
      userManagerData,
      incomes,
      outcomes,
      isDemo,
    };
  },
};
</script>

<style lang="scss">
.outcome-page {

  &__content-block {
    padding-right: 25px;
    display: flex;
    flex-direction: column;
  }

  &__controls {
    display: flex;
    margin-top: 1px;
    border-bottom: 1px solid $grey200;

    &-item {
      margin-left: 12px;

      &:first-child {
        margin-left: 0;
      }

      .secondary-button {
        text-transform: capitalize;
      }
      .popup-dropdown__button {
        text-transform: capitalize;
      }
    }
  }

  &__table {
    margin-top: 16px;
  }

  &__title {
    &-block {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      &-text-area {
        display: flex;
        flex-direction: column;
        max-width: 740px;
      }
    }
  }

  &__subtitle {
    margin-top: 8px;
    color:$grey800;
    font-size: $font-size-regular;
    line-height: 18px;
    font-weight: $font-weight-regular;
  }

  &__tabs-block {
    margin-top: 60px;

    .outcome-table {
      margin-top: 60px;
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .outcome-page {
    &__table {
      margin-top: 24px;
    }

    &__amount-outcome {
      margin-top: 20px;
      .unit-income {
        padding: 0;

        &__info-wrapper {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .outcome-page {
    &__content {
      &-block {
        padding-right: 0;
      }
    }

    &__title {
      &-block {
        margin-top: 35px;
        flex-direction: column;
      }
    }

    &__title {
      height: auto;
    }

    &__controls {
      margin-top: 24px;
      border-bottom: none;
    }

    &__tabs-block {
      margin-top: 20px;

      .tabs__content-slot {
        .outcome-table {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
