import { DOMAIN } from '@/models/constants';

export const API_REGISTRATION = `${DOMAIN}api/v1/users/registration`;
export const API_REGISTRATION_PASSWORD = `${DOMAIN}api/v1/users/information`;
export const API_PASSWORD_RECOVERY = `${DOMAIN}api/v1/users/password/recovery`;
export const API_PASSWORD_SET_NEW = `${DOMAIN}api/v1/users/password/fill`;
export const API_PASSWORD_CHANGE = `${DOMAIN}api/v1/users/password/change`;

export const API_APPROVE_AUTH_CODE = `${DOMAIN}api/v1/users/authentification/code`;
export const API_LOGIN = `${DOMAIN}api/v1/authenticate`;
export const API_GET_USER_LOCATIONS = `${DOMAIN}api/v1/users/locations`;
export const API_GET_USER_UNITS = `${DOMAIN}api/v1/users/actives`;
export const API_GET_USER_UNITS_FULL_INFO = `${DOMAIN}api/v1/users/actives-info`;
export const API_GET_PROFILE_SETTINGS = `${DOMAIN}api/v1/users/data-profile`;
export const API_SET_PROFILE_SETTINGS = `${DOMAIN}api/v1/users/profile`;
export const API_SEND_REQUEST_TO_CHANGE_PROFILE_SETTINGS = `${DOMAIN}api/v1/users/send-change-data-request`;
export const API_GET_FAQ = `${DOMAIN}api/v1/faq`;
export const API_SEND_QUESTION = `${DOMAIN}api/v1/faq/send-question`;
export const API_CAPTCHA_VERIFY = `${DOMAIN}api/v1/captcha-verify`;
export const API_REMOVE_SALE = `${DOMAIN}api/v1/sale-unit/delete-lot`;
export const API_ASSET_SALE = `${DOMAIN}api/v1/sale-unit/offer-unit-for-sale `;
export const API_REQUEST_BUY = `${DOMAIN}api/v1/sale-unit/send-notification`;
export const API_DOCUMENT_SEND = `${DOMAIN}api/v1/user-document/send-document-request`;

export const API_SET_PASSWORD = {
  signUp: API_REGISTRATION_PASSWORD,
  recovery: API_PASSWORD_SET_NEW,
};
